.drillDown-data-wrapper[data-v-d64fbdbe] {
  font-family: PingFang SC-Regular;
  height: calc(100% - 113px);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.drillDown-data-wrapper .drillDown-data-table[data-v-d64fbdbe] {
  height: calc(100%);
}
.drillDown-data-wrapper .drillDown-data-table[data-v-d64fbdbe]  .header-cell {
  color: #2f5584;
  background-color: #e0ecfa;
  font-size: 13px;
  font-weight: 400;
  color: #215796;
  background-color: #e1ebf8;
  font-family: PingFang SC-Regular, PingFang SC;
}
.drillDown-data-wrapper .drillDown-data-table[data-v-d64fbdbe]  .cell-class div {
  word-break: break-word;
}