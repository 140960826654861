.left-content[data-v-320e73ba] {
  width: 420px;
  float: left;
  height: 100%;
}
.left-content .tree-component[data-v-320e73ba] {
  height: calc(100% - 54px);
}
.left-content .node-content[data-v-320e73ba] {
  width: calc(100% - 20px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.left-content .node-label[data-v-320e73ba] {
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.left-content .template-mark[data-v-320e73ba] {
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  margin-right: 4px;
  background-color: #01579b;
  color: #FFFFFF;
}
.right-content[data-v-320e73ba] {
  background-color: #FFFFFF;
  width: calc(100% - 420px);
  height: 100%;
  float: right;
  padding-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}