.setting-drawer-content .setting-drawer-title[data-v-126b135a] {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}
.setting-drawer-content .setting-drawer-block-checbox[data-v-126b135a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.setting-drawer-content .setting-drawer-block-checbox .setting-drawer-block-checbox-item[data-v-126b135a] {
  position: relative;
  margin-right: 16px;
  border-radius: 2px;
  cursor: pointer;
}
.setting-drawer-content .setting-drawer-block-checbox .setting-drawer-block-checbox-item img[data-v-126b135a] {
  width: 48px;
  height: 48px;
}
.setting-drawer-content .setting-drawer-block-checbox .setting-drawer-block-checbox-item .setting-drawer-block-checbox-selectIcon[data-v-126b135a] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  padding-left: 24px;
  color: #1890ff;
  font-weight: 700;
  font-size: 14px;
}
.drawer-container[data-v-126b135a] {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}
.drawer-container .drawer-title[data-v-126b135a] {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 22px;
}
.drawer-container .drawer-item[data-v-126b135a] {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  padding: 12px 0;
}
.drawer-container .drawer-switch[data-v-126b135a] {
  float: right;
}