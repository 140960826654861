.method_instruc_box[data-v-630f6815] {
  margin-bottom: 15px;
}
.method_instruc_box ul[data-v-630f6815],
.method_instruc_box li[data-v-630f6815] {
  margin: 0;
  padding: 0;
}
.method_instruc_box li[data-v-630f6815] {
  list-style: none;
}
.method_instruc_box .instruc_btn.el-button--medium[data-v-630f6815] {
  padding: 7px 20px;
  font-size: 13px;
}
.method_instruc_box[data-v-630f6815]  .el-button--primary:hover,
.method_instruc_box[data-v-630f6815]  .el-button--primary:focus {
  background: #2e74b9;
  background-color: #2e74b9;
  border-color: #2e74b9;
}
.method_instruc_box[data-v-630f6815]  .el-dialog:not(.is-fullscreen) {
  margin-top: 70px !important;
  height: 100%;
  max-height: calc(100vh - 90px);
}
.method_instruc_box[data-v-630f6815]  .el-dialog__wrapper {
  overflow: unset;
  z-index: 100001 !important;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__title,
.method_instruc_box[data-v-630f6815]  .el-dialog__body,
.method_instruc_box[data-v-630f6815]  .el-dialog__footer {
  color: #1f5080;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__header {
  text-align: left;
  padding: 40px 50px 10px;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__body {
  padding: 10px 50px 25px;
  height: calc(100vh - 165px);
  overflow-y: auto;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__body .content {
  font-size: 12px;
  margin-bottom: 10px;
  word-break: break-word;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__body .title_h2,
.method_instruc_box[data-v-630f6815]  .el-dialog__body .title_h1 {
  font-weight: 700;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__body .title_h2 {
  font-size: 12px;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__body .img-formula {
  height: 100px;
  margin: 5px 0;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__body .footer {
  float: right;
  font-size: 12px;
  margin-top: 30px;
}
.method_instruc_box[data-v-630f6815]  .el-dialog__body::-webkit-scrollbar-track {
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
}