.world-map-login[data-v-0bf4fc16] {
  height: 100%;
  width: 100%;
  background-image: url(../../static/img/login-bg.2da46b47.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0;
}
.world-map-login .main-content[data-v-0bf4fc16] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  overflow: auto;
}
.world-map-login .main-content .logo[data-v-0bf4fc16] {
  width: 272px;
  height: 44px;
  background-image: url(../../static/img/dl-logo.895a338f.png);
  background-size: cover;
}
.world-map-login .main-content .title[data-v-0bf4fc16] {
  color: #FFFFFF;
  font-size: 18px;
  margin: 12px 0 40px 0;
}
.world-map-login .main-content .login-box[data-v-0bf4fc16] {
  width: 460px;
  height: 382px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  padding: 32px;
  padding-top: 20px;
}
.world-map-login .main-content .tip-text[data-v-0bf4fc16] {
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 16px;
}
.world-map-login[data-v-0bf4fc16] .el-tabs__header {
  margin-bottom: 24px;
}
.world-map-login[data-v-0bf4fc16] .el-button--text {
  color: #008FBE;
  height: 100%;
}
.world-map-login[data-v-0bf4fc16] .el-button--primary {
  background-color: #008FBE;
  border-color: #008FBE;
}
.world-map-login[data-v-0bf4fc16] .el-button--primary.is-disabled {
  background-color: #A6D3E2;
  border-color: #A6D3E2;
}
.world-map-login[data-v-0bf4fc16] .el-tabs__item.is-active {
  color: #008FBE;
}
.world-map-login[data-v-0bf4fc16] .el-tabs__active-bar {
  background-color: #008FBE;
}
.world-map-login[data-v-0bf4fc16] .el-form-item {
  margin-bottom: 18px;
}