[data-v-3b8671a0] .el-message-box__wrapper {
  background-color: rgba(0, 0, 0, 0.5);
}
.echarts_box[data-v-3b8671a0] {
  position: absolute;
  top: 100vh;
  width: 100%;
}
.FMCCCtop_container[data-v-3b8671a0] {
  position: relative;
  width: 100%;
  background: #dfebf9;
  border-radius: 6px;
  padding-top: 30px;
  padding-bottom: 10px;
}
.FMCCCtop_container .main_box[data-v-3b8671a0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
}
.FMCCCtop_container .main_box .upload_box[data-v-3b8671a0] {
  height: 40px;
  padding: 0 20px;
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-family: "PingFang SC";
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: #2d5380;
  cursor: pointer;
}
.FMCCCtop_container .main_box .upload_box[data-v-3b8671a0]:hover {
  background-color: #4598f7;
  color: #ffffff;
  border: 1px solid #4598f7;
}
.FMCCCtop_container .main_box .form_box[data-v-3b8671a0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 770px;
  height: 80px;
  margin-right: 15px;
  font-family: "PingFang SC";
  font-size: 14px;
  color: #1f5080;
}
.FMCCCtop_container .main_box .form_box .res_box[data-v-3b8671a0] {
  margin-top: 0;
}
.FMCCCtop_container .main_box .form_box .res_box span[data-v-3b8671a0] {
  margin-right: 30px;
}
.FMCCCtop_container .main_box[data-v-3b8671a0]  .el-form-item {
  margin-bottom: 0;
}
.FMCCCtop_container .main_box[data-v-3b8671a0]  .el-form-item__label {
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 14px;
  color: #1f5080;
}
.FMCCCtop_container .main_box .time .el-input[data-v-3b8671a0] {
  width: 140px;
}
.FMCCCtop_container .main_box .nav .el-input[data-v-3b8671a0] {
  width: 110px;
}
.FMCCCtop_container .main_box[data-v-3b8671a0]  .el-input {
  width: 100px;
}
.FMCCCtop_container .main_box[data-v-3b8671a0]  .el-input__inner {
  height: 30px;
  color: #1f5080;
  font-family: "PingFang SC";
}
.FMCCCtop_container .main_box .btn[data-v-3b8671a0] {
  width: 110px;
  height: 40px;
  border-radius: 6px;
  font-family: "PingFang SC";
  font-size: 14px;
}
.FMCCCtop_container .main_box .compute_btn[data-v-3b8671a0] {
  margin-right: 20px;
  background: #4598f7;
  color: #ffffff;
}
.FMCCCtop_container .main_box .down_btn[data-v-3b8671a0] {
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  color: #2d5380;
}
.FMCCCtop_container .main_box .down_btn[data-v-3b8671a0]:hover {
  background: #4598f7;
  border: 1px solid #4598f7;
  color: #ffffff;
}
.FMCCCtop_container .more_box[data-v-3b8671a0] {
  position: relative;
  width: 1200px;
  margin: 15px auto 0 auto;
  height: 30px;
  font-family: "PingFang SC";
  font-size: 14px;
  color: #2d5380;
}
.FMCCCtop_container .more_box .more[data-v-3b8671a0] {
  width: 150px;
  height: 30px;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.FMCCCtop_container .more_box .text[data-v-3b8671a0] {
  margin-left: 6px;
}
.FMCCCtop_container .senior_box[data-v-3b8671a0] {
  height: 0;
  overflow: hidden;
}
.FMCCCtop_container .senior_box .senior_title[data-v-3b8671a0] {
  height: 50px;
  background-color: #ffffff;
  text-align: center;
  font-family: "PingFang SC";
  font-weight: 900;
  font-size: 16px;
  line-height: 50px;
  color: #01579b;
}
.FMCCCtop_container .senior_box .senior_content[data-v-3b8671a0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 40px;
  font-family: "PingFang SC";
  font-weight: 900;
  font-size: 14px;
  line-height: 40px;
  color: #01579b;
  background: #dfebf9;
}
.FMCCCtop_container .senior_box .senior_content .content_left[data-v-3b8671a0] {
  margin-right: 680px;
}
.FMCCCtop_container .senior_box .senior_content .content_right[data-v-3b8671a0]  .el-input {
  width: 90px;
}
.FMCCCtop_container .senior_box .senior_content .content_right[data-v-3b8671a0]  .el-input ::v-deep .el-input__inner {
  height: 30px;
  text-align: center;
}
.FMCCCtop_container .senior_box .senior_table[data-v-3b8671a0] {
  background-color: #ffffff;
}
.FMCCCtop_container .senior_box .senior_table .table[data-v-3b8671a0] {
  width: 1044px;
  height: 120px;
  margin: 0 auto;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  background: #f7f8fb;
}
.FMCCCtop_container .senior_box .senior_table .table td[data-v-3b8671a0] {
  height: 40px;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  text-align: center;
  font-family: "PingFang SC";
  font-weight: 900;
  font-size: 14px;
  color: #1f5080;
}
.FMCCCtop_container .senior_box .senior_table .table td[data-v-3b8671a0]  .el-input {
  width: 90px;
}
.FMCCCtop_container .senior_box .senior_table .table td[data-v-3b8671a0]  .el-input ::v-deep .el-input__inner {
  height: 30px;
  text-align: center;
}
.FMCCCtop_container .senior_box .btn_box[data-v-3b8671a0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 20px;
  height: 140px;
  background-color: #ffffff;
}
.FMCCCtop_container .senior_box .btn_box .btn[data-v-3b8671a0] {
  width: 137px;
  height: 40px;
  font-family: "PingFang SC";
  font-size: 16px;
  border-radius: 6px;
}
.FMCCCtop_container .senior_box .btn_box .use_btn[data-v-3b8671a0] {
  background: #4598f7;
  color: #ffffff;
}
.FMCCCtop_container .senior_box .btn_box .cancel_btn[data-v-3b8671a0] {
  margin-left: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  color: #2d5380;
}
.FMCCCtop_container .active[data-v-3b8671a0] {
  height: 440px;
  padding-top: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.FMCCCtop_container .close[data-v-3b8671a0] {
  height: 0;
  padding-top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.FMCCCtop_container[data-v-3b8671a0]  .el-dialog__body {
  padding: 30px 50px;
}
.FMCCCtop_container[data-v-3b8671a0]  .el-message-box__content {
  padding: 10px 25px;
}
.FMCCCtop_container .dialog-footer[data-v-3b8671a0] {
  width: 360px;
  margin: 0 auto 20px auto;
  text-align: center;
}
.FMCCCtop_container .dialog-footer button[data-v-3b8671a0] {
  width: 170px;
}
.FMCCCtop_container .dialog-footer button.right_btn[data-v-3b8671a0] {
  margin-left: 20px;
}
.FMCCCtop_container[data-v-3b8671a0]  .el-upload-dragger {
  background: -webkit-gradient(linear, left bottom, left top, from(#f4f9ff), to(rgba(223, 235, 249, 0)));
  background: linear-gradient(0deg, #f4f9ff 0%, rgba(223, 235, 249, 0) 100%);
}
.FMCCCtop_container .msg-dialog[data-v-3b8671a0]  .el-dialog__body {
  padding: 10px 15px;
}
.FMCCCtop_container .msg-dialog .dialog-footer button[data-v-3b8671a0] {
  width: unset;
}