.sfdr-notification-download {
  top: 75px !important;
  font-family: PingFang SC-Regular, Arial, sans-serif;
  word-spacing: 0;
  line-height: 20px;
  font-size: 14px;
}
.el-table__body-wrapper::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}
.el-table__fixed,
.el-table__fixed-right {
  height: calc(100% - 7px) !important;
  -webkit-box-shadow: -5px -2px 10px rgba(0, 0, 0, 0.12) !important;
          box-shadow: -5px -2px 10px rgba(0, 0, 0, 0.12) !important;
}
.el-table__body-wrapper.is-scrolling-none ~ .el-table__fixed-right {
  height: 100% !important;
  bottom: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.el-table--scrollable-y .el-table__fixed-right {
  right: 7px !important;
}
.el-table--scrollable-x:not(.el-table--scrollable-y) .el-table__fixed-right {
  right: 0 !important;
}
.el-table th .cell {
  line-height: 16px !important;
  word-break: break-word;
}
.el-tooltip {
  color: #a5a7aa;
  font-size: 14px;
}
.el-tooltip__popper {
  max-width: 580px;
  background: #606266 !important;
  font-size: 13px;
  font-weight: 400;
  color: #ebf3ff;
}