.tree-container[data-v-38d2f2d7] {
  font-size: 14px;
  border-radius: 6px;
  min-width: 225px;
  background-color: #fff;
  color: #01579b;
}
.tree-container .tree-top-title[data-v-38d2f2d7] {
  border-radius: 6px 6px 0 0;
  line-height: 40px;
  padding-left: 10px;
  font-weight: 600;
  background-color: #dfebf9;
}
.tree-container .tree-box[data-v-38d2f2d7] {
  padding-top: 3px;
}
.tree-container .tree-content[data-v-38d2f2d7] {
  height: 100%;
  overflow-y: auto;
}
.tree-container .el-tree[data-v-38d2f2d7] {
  font-size: 13px;
  color: #01579b;
}
.tree-container .tree-node-span[data-v-38d2f2d7] {
  font-weight: 700;
}
.tree-container[data-v-38d2f2d7]  .el-icon-caret-right {
  display: none;
}
.tree-container .el-icon-indicator[data-v-38d2f2d7],
.tree-container .el-icon-arrow-blue[data-v-38d2f2d7] {
  margin-right: 10px;
}
.tree-container .el-icon-arrow-blue[data-v-38d2f2d7] {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.tree-container .el-icon-arrow-blue .svg-icon[data-v-38d2f2d7] {
  width: 0.7em;
}
.tree-container .el-icon-second[data-v-38d2f2d7] {
  margin-right: 4px;
}
.el-tree-node.is-expanded .el-icon-arrow-blue[data-v-38d2f2d7] {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
[data-v-38d2f2d7] .el-tree-node__children {
  padding: 0 10px;
}
[data-v-38d2f2d7] .el-tree-node__children .el-tree-node__content:hover {
  background-color: #dfebf9 !important;
}
[data-v-38d2f2d7] .el-tree-node__content {
  padding: 0px 5px !important;
  height: 22px;
  line-height: 19px;
}
.children-list[data-v-38d2f2d7] {
  max-width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
[data-v-38d2f2d7].el-tree-node__label {
  padding-left: 15px;
}
@media screen and (min-width: 1790px) {
.tree-container .children-list[data-v-38d2f2d7] {
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}
}