.tip_container[data-v-53b52ba0]  .el-table .el-table__header-wrapper th,
.tip_container[data-v-53b52ba0]  .el-table .el-table__fixed-header-wrapper th {
  font-family: "PingFang SC";
  font-weight: 900;
  font-size: 16px;
  background-color: #DFEBF9;
  color: #1F5080;
}
.tip_container .tip_title[data-v-53b52ba0] {
  width: 100%;
  height: 50px;
  font-family: "PingFang SC";
  font-weight: 900;
  font-size: 16px;
  line-height: 10px;
  text-align: center;
  color: #1F5080;
}
.tip_container .text[data-v-53b52ba0] {
  padding: 36px 20px 20px 33px;
  font-family: "PingFang SC";
  font-size: 14px;
  line-height: 22px;
  color: #1F5080;
}
.tip_container .table[data-v-53b52ba0] {
  width: 100%;
}
.tip_container .table .column1[data-v-53b52ba0] {
  width: 285px;
}
.tip_container .table .column2[data-v-53b52ba0] {
  width: 700px;
}
.tip_container .table .column3[data-v-53b52ba0] {
  width: 480px;
}
.tip_container .table .column4[data-v-53b52ba0] {
  width: 290px;
}
.tip_container .table .item[data-v-53b52ba0] {
  height: 40px;
  text-align: center;
  background: #DFEBF9;
  border-radius: 6px;
  font-family: "PingFang SC";
  font-size: 14px;
  line-height: 40px;
  color: #1F5080;
}
.tip_container .table .item.item_img[data-v-53b52ba0] {
  background-color: #ffffff;
}
.tip_container .table .item.item_title[data-v-53b52ba0] {
  font-weight: 900;
}