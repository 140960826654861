.template-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.secCode-select-popper .secCode-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.secCode-select-popper .secCode-content .secname {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.secCode-select-popper .secCode-content .secname .delisting {
  background-color: red;
  color: #FFFFFF;
  padding: 0 2px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  margin-left: 2px;
}
.secCode-select-popper .el-select-dropdown__item {
  padding-right: 20px !important;
}
.seccode-popover .seccode-tag {
  max-width: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  gap: 10px;
}