.company-container[data-v-6b5de400] {
  font-size: 14px;
  border-radius: 6px;
  background-color: #fff;
  color: #01579b;
}
.company-container .company-top-title[data-v-6b5de400] {
  border-radius: 6px 6px 0 0;
  line-height: 40px;
  padding-left: 10px;
  font-weight: 600;
  background-color: #dfebf9;
}
.company-container .company-collapse[data-v-6b5de400]  .el-collapse-item__content {
  padding-bottom: 0px;
}
.company-container .company-collapse[data-v-6b5de400]  .el-collapse-item__arrow {
  display: none;
}
.company-container .company-table[data-v-6b5de400] {
  height: 100%;
  overflow-y: auto;
}
.company-container .company-table .cursor-btn[data-v-6b5de400] {
  cursor: pointer;
}