.tabs_container[data-v-10452948] {
  position: relative;
  background: #f3f4f7;
  border-radius: 6px;
}
.tabs_container[data-v-10452948]  .el-tabs__header {
  margin: 0;
}
.tabs_container[data-v-10452948]  .el-tabs__content {
  background-color: #FFFFFF;
  padding-top: 34px;
  margin-bottom: 30px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.tabs_container[data-v-10452948]  .el-tabs__item {
  width: 200px;
  height: 40px;
  padding: 0;
  margin-right: 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;
  background: #DFEBF9;
  color: #01579B;
  font-size: 16px;
  line-height: 40px;
  font-family: "PingFang SC";
  font-weight: 900;
}
.tabs_container[data-v-10452948]  .el-tabs__item:nth-child(4) {
  margin-bottom: 1px;
  width: 240px;
}
.tabs_container[data-v-10452948]  .el-tabs__item.is-active {
  background: #FFFFFF;
}
.tabs_container[data-v-10452948]  .el-tabs__nav-wrap::after {
  position: static;
}
.tabs_container[data-v-10452948]  .el-tabs__active-bar {
  width: 0 !important;
  height: 0 !important;
}
.tabs_container .tip[data-v-10452948] {
  position: absolute;
  right: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 999;
}