.container[data-v-03b7eb90] {
  width: 900px;
  margin: 0 auto;
  position: relative;
}
.navbar-sub[data-v-03b7eb90] {
  padding: 0 20px;
  height: 55px;
  line-height: 50px;
  width: 100%;
  position: fixed;
  top: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #fff;
  background-color: #fff;
  z-index: 9999;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  overflow: hidden;
}