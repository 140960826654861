.sfdr[data-v-1ab0cd25] {
  padding: 20px 16px 0;
  height: calc(100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  font-family: PingFang SC-Regular;
  font-size: 13px !important;
}
.sfdr .menu[data-v-1ab0cd25] {
  margin-bottom: 20px;
  font-size: 13px !important;
}
.sfdr .page-wrapper[data-v-1ab0cd25] {
  height: calc(100%);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.sfdr[data-v-1ab0cd25]  .el-button--primary {
  background: #5998f0;
}
.sfdr[data-v-1ab0cd25]  .el-button--primary:hover {
  background: #5998f0;
  opacity: 0.6;
  border-color: rgba(89, 152, 240, 0.6);
}
.sfdr[data-v-1ab0cd25]  .el-button--default:hover {
  background: #fafafa;
}
.sfdr[data-v-1ab0cd25]  .help-button {
  position: absolute;
  top: 15px;
  right: 16px;
  width: 102px;
  height: 30px;
  padding: 5px 14px;
}
.sfdr[data-v-1ab0cd25]  .help-button ::v-deep span {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.sfdr[data-v-1ab0cd25]  .help-button:hover {
  background: #5998f0;
  color: #fff;
}
.sfdr .fade-leave[data-v-1ab0cd25] {
  opacity: 1;
}
.sfdr .fade-leave-active[data-v-1ab0cd25] {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.sfdr .fade-leave-to[data-v-1ab0cd25] {
  opacity: 0;
}
.sfdr .fade-enter[data-v-1ab0cd25] {
  opacity: 0;
}
.sfdr .fade-enter-active[data-v-1ab0cd25] {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.sfdr .fade-enter-to[data-v-1ab0cd25] {
  opacity: 1;
}
.sfdr .top-enter-active[data-v-1ab0cd25],
.sfdr .top-leave-active[data-v-1ab0cd25] {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
}
.sfdr .top-leave[data-v-1ab0cd25] {
  opacity: 1;
  left: 16px;
}
.sfdr .top-enter[data-v-1ab0cd25],
.sfdr .top-leave-to[data-v-1ab0cd25] {
  opacity: 0;
  left: 90%;
}
.sfdr[data-v-1ab0cd25]  .el-dialog:not(.is-fullscreen) {
  margin-top: 15vh !important;
}
.sfdr[data-v-1ab0cd25]  .el-dialog__body {
  word-break: break-word;
}
.sfdr[data-v-1ab0cd25]  .sfdr-portfolio-table-total-cell {
  background: #f5f5f5;
}