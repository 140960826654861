.select-item[data-v-e793d252] {
  height: 54px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -ms-flex-preferred-size: 400px;
      flex-basis: 400px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ebedf0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 4px 0px 10px;
  margin: 0 12px 8px 0;
}
.select-item .select-item-label[data-v-e793d252] {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 300;
  color: #606266;
  line-height: 20px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
}
.select-item .selected-icon[data-v-e793d252] {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 24px;
  height: 18px;
}
.selected[data-v-e793d252] {
  border: 2px solid #5998f0;
}
.select-item[data-v-e793d252]:hover {
  border: 1px solid #5998f0;
}
.selected[data-v-e793d252]:hover {
  border: 2px solid #5998f0;
}