.account-center[data-v-43619f8c] {
  height: calc(100vh - 120px);
  padding: 20px;
}
.account-center div[data-v-43619f8c],
.account-center .head[data-v-43619f8c],
.account-center[data-v-43619f8c]  .el-form-item__label {
  color: #01579b;
  font-weight: 500;
}
.account-center .head[data-v-43619f8c] {
  font-weight: 700;
}
.account-center .nick-name[data-v-43619f8c] {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}
.account-center .box-card[data-v-43619f8c],
.account-center .api-repository[data-v-43619f8c] {
  height: calc(100vh - 170px);
}
.account-center .box-card .el-tabs__item[data-v-43619f8c],
.account-center .api-repository .el-tabs__item[data-v-43619f8c] {
  padding: 0 50px;
}
.account-center[data-v-43619f8c]  .el-card__body {
  padding: 15px;
  padding-top: 0;
  height: 100%;
}
.account-center .box-card[data-v-43619f8c] {
  margin-right: 20px;
  border: unset;
  background-color: #01579b;
  background-image: url(../../static/img/avat-bg.55dbbca3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.account-center .left-avatar[data-v-43619f8c] {
  margin-top: 80px;
}
.account-center .api-repository[data-v-43619f8c]  .el-card__header {
  border-bottom: unset;
}
@media (max-width: 768px) {
.account-center .el-col-middle[data-v-43619f8c] {
    margin: 10px 0;
}
}
@media screen and (min-width: 1770px) {
.account-center[data-v-43619f8c] {
    padding: 80px 20px;
    height: calc(100vh - 200px);
}
.account-center .box-card[data-v-43619f8c],
.account-center .api-repository[data-v-43619f8c] {
    height: calc(100vh - 300px);
}
}