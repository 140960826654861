.footer[data-v-395f4454] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 6px 0 16px;
}
.footer .warning-message[data-v-395f4454] {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ed6a0c;
  line-height: 16px;
  width: 100%;
  margin-bottom: 12px;
}
.footer .btn-container[data-v-395f4454] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer[data-v-395f4454] {
  padding: 16px 0;
}
.review-page[data-v-395f4454] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100% - 100px);
}
.review-page .review-main[data-v-395f4454] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: calc(100%);
}
.review-page .review-main .review-left[data-v-395f4454] {
  width: 170px;
  padding: 16px 16px;
  background: #ffffff;
  margin-right: 16px;
}
.review-page .review-main .review-right[data-v-395f4454] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
}
.review-page .review-main .review-right[data-v-395f4454]  .summary-data-wrapper {
  margin-bottom: 22px;
}
.review-page .review-main .review-right-fixed[data-v-395f4454] {
  height: 100%;
}
.review-page .review-main .fixed-table-container[data-v-395f4454] {
  height: 100%;
}
.review-page .review-main .fixed-table-container[data-v-395f4454]  .summary-data-wrapper {
  height: calc(100%);
}
.review-page .fade-enter-active[data-v-395f4454],
.review-page .fade-leave-active[data-v-395f4454] {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.review-page .fade-enter[data-v-395f4454],
.review-page .fade-leave-to[data-v-395f4454] {
  opacity: 0;
}