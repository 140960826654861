.worldMapBoard .amap-copyright {
  display: none !important;
}
.worldMapBoard .l7-control-container .l7-column.l7-right {
  bottom: 330px !important;
  right: 20px !important;
}
.worldMapBoard .l7-popup .l7-popup-content {
  padding: 0;
}
.worldMapBoard .l7-popup-anchor-bottom .l7-popup-tip {
  display: none;
}