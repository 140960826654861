.template-category[data-v-9cece19e] {
  padding: 20px;
  background-color: #FFFFFF;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.template-category .table-area[data-v-9cece19e] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 0;
}
[data-v-9cece19e] .vue-treeselect--has-value .vue-treeselect__input {
  vertical-align: middle;
}