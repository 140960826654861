.welcome-title[data-v-b60830a8] {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.welcome-title span[data-v-b60830a8] {
  z-index: 1;
}
.welcome-title .bottom[data-v-b60830a8] {
  position: relative;
  bottom: 3px;
  width: calc(57%);
  height: 6px;
  background: #215796;
}