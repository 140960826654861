.el-select-dropdown__item {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #323233;
}
.el-select-dropdown__item:hover {
  background: #e1ebf8;
}
.el-select-dropdown {
  margin-top: 2px !important;
}
.el-select-dropdown .popper__arrow {
  display: none;
}