.menu[data-v-99d93e36] {
  margin-top: 20px;
}
.outer-box[data-v-99d93e36] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
}
.outer-box .left-box[data-v-99d93e36] {
  height: calc(100vh - 215px);
  max-height: calc(100vh - 220px);
  margin-right: 20px;
  margin-top: 30px;
}
.outer-box .content-right[data-v-99d93e36] {
  width: 200px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  background-color: #fff;
  padding: 0 15px;
}