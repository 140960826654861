@charset "UTF-8";
/* 修复表格fixed后错乱问题 */
body .el-table th.gutter {
  display: table-cell !important;
}
.el-table_fixed-right {
  height: 100% !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
}
::-webkit-scrollbar-thumb:vertical {
  height: 6px;
  background-color: #c2c2c2;
  -webkit-border-radius: 6px;
}
::-webkit-scrollbar-thumb:horizontal {
  width: 6px;
  background-color: #c2c2c2;
  -webkit-border-radius: 6px;
}
::-webkit-scrollbar-thumb:vertical:hover, ::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #808080;
}