[data-v-d0288276] .el-button--primary {
  background-color: transparent;
  border-color: transparent;
  height: 30px;
  width: 82px;
  padding: unset;
}
[data-v-d0288276] .el-button--primary:hover,[data-v-d0288276] .el-button--primary:focus {
  background: #2e74b9;
  background-color: #2e74b9;
  border-color: #2e74b9;
}
[data-v-d0288276] .el-button--primary:hover .el-arrow-white,[data-v-d0288276] .el-button--primary:focus .el-arrow-white {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.lang-select.el-dropdown-menu.el-dropdown-menu--medium[data-v-d0288276] {
  z-index: 20001 !important;
  top: 32px !important;
}
[data-v-d0288276] .popper__arrow {
  display: none !important;
}
.el-dropdown-menu__item[data-v-d0288276]:not(.is-disabled):hover,
.el-dropdown-menu__item[data-v-d0288276]:focus {
  width: 100%;
  background-color: #dfebf9;
  color: #14487b !important;
}
.el-dropdown-menu--medium[data-v-d0288276] {
  padding: unset;
}
.el-dropdown-menu__item[data-v-0ea2bf94][data-v-d0288276]:not(.is-disabled):hover,
.el-dropdown-menu__item[data-v-0ea2bf94][data-v-d0288276]:focus {
  border-radius: 4px;
}
.el-dropdown-menu__item[data-v-d0288276] {
  color: #01579b;
}
.el-arrow-white[data-v-d0288276] {
  display: inline-block;
}
.el-arrow-white .svg-icon[data-v-d0288276] {
  width: 0.7em;
}