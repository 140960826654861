.navbar-sub[data-v-3e0741e8] {
  height: 50px;
  line-height: 50px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #fff;
}
.navbar-sub .top-navbar-btns .el-button[data-v-3e0741e8] {
  color: #205080;
  border: 1px solid #D9D9D9;
}
.navbar-sub .top-navbar-btns .el-button.el-button[data-v-3e0741e8]:hover,
.navbar-sub .top-navbar-btns .el-button.el-button[data-v-3e0741e8]:focus,
.navbar-sub .top-navbar-btns .el-button.el-button[data-v-3e0741e8]:active {
  background-color: #4598f7;
  border-color: #4598f7;
  color: #fff;
}
.navbar-sub .top-navbar-btns .el-button--medium[data-v-3e0741e8] {
  padding: 7px 20px;
  border-radius: 6px;
}