.empty-block[data-v-6751fe79] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: PingFang SC-Regular;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  overflow-x: hidden !important;
}
.empty-block .empty-tips[data-v-6751fe79] {
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 400;
  color: #215796;
  line-height: 20px;
}
.empty-block .upload-btn[data-v-6751fe79] {
  opacity: 1;
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-bottom: 20px;
  margin-top: 28px;
}
.empty-block .download-template[data-v-6751fe79] {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #215796;
  line-height: 20px;
}