.nav-outer-box[data-v-0d4a6dc5] {
  top: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
}
.nav-outer-box .container[data-v-0d4a6dc5] {
  margin: 0 auto;
  position: relative;
}
.navbar[data-v-0d4a6dc5] {
  height: 50px;
  padding: 0 25px;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  background-color: #01579b;
  width: 100%;
}
.navbar .logo[data-v-0d4a6dc5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.navbar .logo a[data-v-0d4a6dc5] {
  margin-right: 20px;
  display: block;
  height: 50px;
  line-height: 60px;
  width: 100%;
}
.navbar .logo a .svg-icon[data-v-0d4a6dc5] {
  height: 50px;
  width: 116px;
}
.navbar .topmenu-container[data-v-0d4a6dc5] {
  margin-top: unset;
}
.navbar .errLog-container[data-v-0d4a6dc5] {
  display: inline-block;
  vertical-align: top;
}
.navbar .right-menu[data-v-0d4a6dc5]:focus {
  outline: none;
}
.navbar .right-menu .right-menu-item[data-v-0d4a6dc5] {
  display: inline-block;
  margin-left: 8px;
  height: 100%;
  font-size: 21px;
  color: #fff;
  vertical-align: text-bottom;
}
.navbar .right-menu .right-menu-item.hover-effect[data-v-0d4a6dc5] {
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.navbar .right-menu .avatar-container .avatar-wrapper[data-v-0d4a6dc5] {
  margin-top: 5px;
  position: relative;
}
@media screen and (min-width: 1790px) {
.navbar[data-v-0d4a6dc5] {
    padding: 0 20px;
}
}
@media (max-width: 769px) {
.navbar .logo[data-v-0d4a6dc5] {
    min-width: 100px;
}
.navbar .logo a[data-v-0d4a6dc5] {
    background-size: 100% 80%;
}
}