.sfdr-portfolio-data-tabs {
  height: 91%;
}
.sfdr-portfolio-data-tabs .el-tabs__header {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.sfdr-portfolio-data-tabs .el-tabs__content {
  height: calc(100% - 56px);
}
.sfdr-portfolio-data-tabs .el-tabs__content > div {
  height: calc(100%);
}
.sfdr-portfolio-data-tabs .el-tabs__item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 13px !important;
  border-bottom-color: #5998f0 !important;
}
.sfdr-portfolio-data-tabs .is-active {
  color: #5998f0;
  border: 1px solid #5998f0 !important;
  border-bottom-color: white !important;
  background-color: white;
  font-weight: 400;
  z-index: 100;
}
.sfdr-portfolio-data-tabs .el-tabs__header {
  margin-bottom: 0;
  position: relative;
  top: 2px;
  z-index: 0;
}
.sfdr-portfolio-data-tabs .el-table__empty-block {
  width: 100% !important;
}