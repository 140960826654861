[data-v-0ce84a58] .el-table::before {
  height: 0;
}
.fileTable[data-v-0ce84a58] {
  border-radius: 6px;
}
.fileTable[data-v-0ce84a58]  .tableHead th {
  font-family: "PingFang SC";
  font-weight: 900;
  height: 40px;
  font-size: 14px;
  color: #01579B;
  background: #DFEBF9;
}
.fileTable[data-v-0ce84a58]  .el-table__empty-text {
  display: inline-block;
  height: 278px;
  line-height: 278px;
  width: 100%;
  font-size: 24px;
  color: #01579B;
  background-color: #f5f6f8;
  font-family: "PingFang SC";
  font-weight: 900;
}
.fileTable[data-v-0ce84a58]  .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #4598F7;
}
.pagination[data-v-0ce84a58] {
  margin: 10px 10px 40px 0;
  text-align: right;
}
[data-v-0ce84a58] .el-pager li {
  font-weight: 400;
}