/**
$base-menu-color:hsla(0,0%,100%,.65);
$base-menu-color-active:#fff;
$base-menu-background:#001529;
$base-logo-title-color: #ffffff;

$base-menu-light-color:rgba(0,0,0,.70);
$base-menu-light-background:#ffffff;
$base-logo-light-title-color: #001529;

$base-sub-menu-background:#000c17;
$base-sub-menu-hover:#001528;
*/

/* fade */
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.28s;
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}

.breadcrumb-move {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

.el-upload input[type=file] {
  display: none !important;
}

.el-upload__input {
  display: none;
}

.cell .el-tag {
  margin-right: 0px;
}

.small-padding .cell {
  padding-left: 5px;
  padding-right: 5px;
}

.fixed-width .el-button--mini {
  padding: 7px 10px;
  width: 60px;
}

.status-col .cell {
  padding: 0 10px;
  text-align: center;
}
.status-col .cell .el-tag {
  margin-right: 0px;
}

.el-dialog {
  -webkit-transform: none;
          transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
}

.upload-container .el-upload {
  width: 100%;
}
.upload-container .el-upload .el-upload-dragger {
  width: 100%;
  height: 200px;
}

.el-dropdown-menu a {
  display: block;
}

.el-range-editor.el-input__inner {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.el-range-separator {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.el-menu--collapse > div > .el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

.el-message {
  margin-top: 120px !important;
}

#app .main-container {
  min-height: 100%;
  -webkit-transition: margin-left 0.28s;
  transition: margin-left 0.28s;
  margin-left: 200px;
  position: relative;
}
#app .sidebarHide {
  margin-left: 0 !important;
}
#app .sidebar-container {
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
  width: 200px !important;
  background-color: #304156;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  -webkit-box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
}
#app .sidebar-container .horizontal-collapse-transition {
  -webkit-transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
}
#app .sidebar-container .scrollbar-wrapper {
  overflow-x: hidden !important;
}
#app .sidebar-container .el-scrollbar__bar.is-vertical {
  right: 0px;
}
#app .sidebar-container .el-scrollbar {
  height: 100%;
}
#app .sidebar-container.has-logo .el-scrollbar {
  height: calc(100% - 50px);
}
#app .sidebar-container .is-horizontal {
  display: none;
}
#app .sidebar-container a {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
#app .sidebar-container .svg-icon {
  margin-right: 16px;
}
#app .sidebar-container .el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}
#app .sidebar-container .el-menu-item,
#app .sidebar-container .el-submenu__title {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
#app .sidebar-container .submenu-title-noDropdown:hover,
#app .sidebar-container .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
#app .sidebar-container .theme-dark .is-active > .el-submenu__title {
  color: #f4f4f5 !important;
}
#app .sidebar-container .nest-menu .el-submenu > .el-submenu__title, #app .sidebar-container .el-submenu .el-menu-item {
  min-width: 200px !important;
}
#app .sidebar-container .nest-menu .el-submenu > .el-submenu__title:hover, #app .sidebar-container .el-submenu .el-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
#app .sidebar-container .theme-dark .nest-menu .el-submenu > .el-submenu__title, #app .sidebar-container .theme-dark .el-submenu .el-menu-item {
  background-color: #1f2d3d !important;
}
#app .sidebar-container .theme-dark .nest-menu .el-submenu > .el-submenu__title:hover, #app .sidebar-container .theme-dark .el-submenu .el-menu-item:hover {
  background-color: #001528 !important;
}
#app .el-menu-item:hover {
  color: #fff;
}
#app .hideSidebar .sidebar-container {
  width: 54px !important;
}
#app .hideSidebar .main-container {
  margin-left: 54px;
}
#app .hideSidebar .submenu-title-noDropdown {
  padding: 0 !important;
  position: relative;
}
#app .hideSidebar .submenu-title-noDropdown .el-tooltip {
  padding: 0 !important;
}
#app .hideSidebar .submenu-title-noDropdown .el-tooltip .svg-icon {
  margin-left: 20px;
}
#app .hideSidebar .el-submenu {
  overflow: hidden;
}
#app .hideSidebar .el-submenu > .el-submenu__title {
  padding: 0 !important;
}
#app .hideSidebar .el-submenu > .el-submenu__title .svg-icon {
  margin-left: 20px;
}
#app .hideSidebar .el-menu--collapse .el-submenu > .el-submenu__title > span {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
#app .el-menu--collapse .el-menu .el-submenu {
  min-width: 200px !important;
}
#app .mobile .main-container {
  margin-left: 0px;
}
#app .mobile .sidebar-container {
  -webkit-transition: -webkit-transform 0.28s;
  transition: -webkit-transform 0.28s;
  transition: transform 0.28s;
  transition: transform 0.28s, -webkit-transform 0.28s;
  width: 200px !important;
}
#app .mobile.hideSidebar .sidebar-container {
  pointer-events: none;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transform: translate3d(-200px, 0, 0);
          transform: translate3d(-200px, 0, 0);
}
#app .withoutAnimation .main-container,
#app .withoutAnimation .sidebar-container {
  -webkit-transition: none;
  transition: none;
}

.el-menu--vertical > .el-menu .svg-icon {
  margin-right: 16px;
}
.el-menu--vertical .nest-menu .el-submenu > .el-submenu__title:hover,
.el-menu--vertical .el-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
.el-menu--vertical > .el-menu--popup {
  max-height: 100vh;
  overflow-y: auto;
}
.el-menu--vertical > .el-menu--popup::-webkit-scrollbar-track-piece {
  background: #d3dce6;
}
.el-menu--vertical > .el-menu--popup::-webkit-scrollbar {
  width: 6px;
}
.el-menu--vertical > .el-menu--popup::-webkit-scrollbar-thumb {
  background: #99a9bf;
  border-radius: 20px;
}

/**
$base-menu-color:hsla(0,0%,100%,.65);
$base-menu-color-active:#fff;
$base-menu-background:#001529;
$base-logo-title-color: #ffffff;

$base-menu-light-color:rgba(0,0,0,.70);
$base-menu-light-background:#ffffff;
$base-logo-light-title-color: #001529;

$base-sub-menu-background:#000c17;
$base-sub-menu-hover:#001528;
*/

.blue-btn {
  background: #324157;
}
.blue-btn:hover {
  color: #324157;
}
.blue-btn:hover:before, .blue-btn:hover:after {
  background: #324157;
}

.light-blue-btn {
  background: #3A71A8;
}
.light-blue-btn:hover {
  color: #3A71A8;
}
.light-blue-btn:hover:before, .light-blue-btn:hover:after {
  background: #3A71A8;
}

.red-btn {
  background: #C03639;
}
.red-btn:hover {
  color: #C03639;
}
.red-btn:hover:before, .red-btn:hover:after {
  background: #C03639;
}

.pink-btn {
  background: #E65D6E;
}
.pink-btn:hover {
  color: #E65D6E;
}
.pink-btn:hover:before, .pink-btn:hover:after {
  background: #E65D6E;
}

.green-btn {
  background: #30B08F;
}
.green-btn:hover {
  color: #30B08F;
}
.green-btn:hover:before, .green-btn:hover:after {
  background: #30B08F;
}

.tiffany-btn {
  background: #4AB7BD;
}
.tiffany-btn:hover {
  color: #4AB7BD;
}
.tiffany-btn:hover:before, .tiffany-btn:hover:after {
  background: #4AB7BD;
}

.yellow-btn {
  background: #FEC171;
}
.yellow-btn:hover {
  color: #FEC171;
}
.yellow-btn:hover:before, .yellow-btn:hover:after {
  background: #FEC171;
}

.pan-btn {
  font-size: 14px;
  color: #fff;
  padding: 14px 36px;
  border-radius: 8px;
  border: none;
  outline: none;
  -webkit-transition: 600ms ease all;
  transition: 600ms ease all;
  position: relative;
  display: inline-block;
}
.pan-btn:hover {
  background: #fff;
}
.pan-btn:hover:before, .pan-btn:hover:after {
  width: 100%;
  -webkit-transition: 600ms ease all;
  transition: 600ms ease all;
}
.pan-btn:before, .pan-btn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  -webkit-transition: 400ms ease all;
  transition: 400ms ease all;
}
.pan-btn::after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.custom-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  color: #fff;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
}

p {
  margin: 0;
  border: 0;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}

label {
  font-weight: 700;
}

html {
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#app {
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

.no-padding {
  padding: 0px !important;
}

.padding-content {
  padding: 4px 0;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

div:focus {
  outline: none;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.block {
  display: block;
}

.pointer {
  cursor: pointer;
}

.inlineBlock {
  display: block;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

aside {
  background: #eef1f6;
  padding: 8px 24px;
  margin-bottom: 20px;
  border-radius: 2px;
  display: block;
  line-height: 32px;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #2c3e50;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
aside a {
  color: #337ab7;
  cursor: pointer;
}
aside a:hover {
  color: #20a0ff;
}

.app-container {
  padding: 20px;
  padding-top: unset;
  height: 100%;
  overflow: auto;
}

.components-container {
  margin: 30px 50px;
  position: relative;
}

.pagination-container {
  margin-top: 30px;
}

.text-center {
  text-align: center;
}

.sub-navbar {
  height: 50px;
  line-height: 50px;
  position: relative;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  -webkit-transition: 600ms ease position;
  transition: 600ms ease position;
  background: -webkit-gradient(linear, left top, right top, from(#20b6f9), color-stop(0%, #20b6f9), color-stop(100%, #2178f1), to(#2178f1));
  background: linear-gradient(90deg, #20b6f9 0%, #20b6f9 0%, #2178f1 100%, #2178f1 100%);
}
.sub-navbar .subtitle {
  font-size: 20px;
  color: #fff;
}
.sub-navbar.draft {
  background: #d0d0d0;
}
.sub-navbar.deleted {
  background: #d0d0d0;
}

.link-type,
.link-type:focus {
  color: #337ab7;
  cursor: pointer;
}
.link-type:hover,
.link-type:focus:hover {
  color: #20a0ff;
}

.filter-container {
  padding-bottom: 10px;
}
.filter-container .filter-item {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px;
}

.multiselect {
  line-height: 16px;
}

.multiselect--active {
  z-index: 1000 !important;
}

.nav-line.el-divider--horizontal {
  margin: unset;
  height: 10px;
  background: -webkit-gradient(linear, left top, right top, from(#f3cc47), color-stop(#d66e2a), to(#f3cc47));
  background: linear-gradient(to right, #f3cc47, #d66e2a, #f3cc47);
}

.instructions-box .el-table .el-table__header-wrapper th,
.instructions-box .el-table .el-table__fixed-header-wrapper th {
  color: #1F5080;
  font-weight: 400;
  background-color: #DFEBF9;
}

.app-container ::-webkit-scrollbar,
.app-container ::-webkit-scrollbar,
.tree-container ::-webkit-scrollbar,
.tree-container ::-webkit-scrollbar,
.instructions-box ::-webkit-scrollbar,
.instructions-box ::-webkit-scrollbar,
.key-world-dialog ::-webkit-scrollbar,
.key-world-dialog ::-webkit-scrollbar,
.help-box ::-webkit-scrollbar,
.help-box ::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  -webkit-transition: background-color 0.9s;
  transition: background-color 0.9s;
}
.app-container ::-webkit-scrollbar-track,
.app-container ::-webkit-scrollbar-track,
.tree-container ::-webkit-scrollbar-track,
.tree-container ::-webkit-scrollbar-track,
.instructions-box ::-webkit-scrollbar-track,
.instructions-box ::-webkit-scrollbar-track,
.key-world-dialog ::-webkit-scrollbar-track,
.key-world-dialog ::-webkit-scrollbar-track,
.help-box ::-webkit-scrollbar-track,
.help-box ::-webkit-scrollbar-track {
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
  border-radius: 2px;
}
.app-container ::-webkit-scrollbar-thumb,
.app-container ::-webkit-scrollbar-thumb,
.tree-container ::-webkit-scrollbar-thumb,
.tree-container ::-webkit-scrollbar-thumb,
.instructions-box ::-webkit-scrollbar-thumb,
.instructions-box ::-webkit-scrollbar-thumb,
.key-world-dialog ::-webkit-scrollbar-thumb,
.key-world-dialog ::-webkit-scrollbar-thumb,
.help-box ::-webkit-scrollbar-thumb,
.help-box ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  min-height: 20px;
  background-clip: content-box;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6) inset;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6) inset;
}
.app-container ::-webkit-scrollbar-conner,
.app-container ::-webkit-scrollbar-conner,
.tree-container ::-webkit-scrollbar-conner,
.tree-container ::-webkit-scrollbar-conner,
.instructions-box ::-webkit-scrollbar-conner,
.instructions-box ::-webkit-scrollbar-conner,
.key-world-dialog ::-webkit-scrollbar-conner,
.key-world-dialog ::-webkit-scrollbar-conner,
.help-box ::-webkit-scrollbar-conner,
.help-box ::-webkit-scrollbar-conner {
  background: transparent;
}

.common-table-style .el-table .el-table__header-wrapper th,
.common-table-style .el-table .el-table__fixed-header-wrapper th,
.common-table-style .el-table__fixed-right-patch,
.common-table-style .el-table__header-wrapper {
  background-color: #dfebf9;
}
.common-table-style .el-table .el-table__fixed-header-wrapper th,
.common-table-style .el-table__fixed {
  color: #1f5080;
}
.common-table-style .el-table__fixed::before,
.common-table-style .el-table__fixed-right::before {
  content: unset;
}
.common-table-style .el-table__empty-block {
  width: 100% !important;
}