.tree-component[data-v-ea0dda7c] {
  height: 100%;
}
.tree[data-v-ea0dda7c] {
  height: calc(100% - 47px);
  font-size: 13px;
  color: #01579b;
  overflow: auto;
}
.tree .custom-tree-node[data-v-ea0dda7c] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  width: 100%;
}
.tree .custom-tree-node .node-label[data-v-ea0dda7c] {
  max-width: calc(100% - 0px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.tree .el-icon-arrow-blue[data-v-ea0dda7c] {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-right: 6px;
}
.tree .el-icon-arrow-blue .svg-icon[data-v-ea0dda7c] {
  width: 0.7em;
}
.tree[data-v-ea0dda7c]  .el-tree-node__expand-icon.is-leaf {
  visibility: hidden;
}
.tree[data-v-ea0dda7c]  .el-tree-node__expand-icon {
  color: #01579B;
  font-size: 14px;
}
.tree[data-v-ea0dda7c]  .el-tree-node__children .el-tree-node__content:hover {
  background-color: #dfebf9 !important;
}