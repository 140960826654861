.sfdr-upload-error-box .el-message-box__header {
  padding-top: 18px;
  -webkit-box-shadow: inset 0px -1px 0px 0px #ebedf0;
          box-shadow: inset 0px -1px 0px 0px #ebedf0;
}
.sfdr-upload-error-box .el-message-box__header .el-message-box__title {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  line-height: 20px;
}
.sfdr-upload-error-box .el-message-box__header .el-message-box__status {
  font-size: 20px !important;
}
.sfdr-upload-error-box .el-message-box__content {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}
.sfdr-upload-error-box .el-message-box__content .el-message-box__message {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 20px;
}