.FMCCC_container {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  padding: 17px 20px 0 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-y: auto;
  overflow-x: auto;
}
.FMCCC_container .cell {
  word-break: keep-all !important;
}
.FMCCC_container .init_table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}
.FMCCC_container .res_box {
  margin-top: 20px;
}
.FMCCC_container .el-dialog:not(.is-fullscreen) {
  margin-top: 10vh !important;
  border-radius: 6px;
}
.FMCCC_container .el-dialog__wrapper {
  background-color: rgba(0, 0, 0, 0.5);
}