.portfolio-data-wrapper[data-v-7da3ff1f] {
  font-family: PingFang SC-Regular;
  height: calc(100%);
  width: 100%;
}
.portfolio-data-wrapper .portfolio-data-table[data-v-7da3ff1f] {
  height: calc(100%);
  overflow: hidden;
  border-top: 1px solid #5998f0;
}
.portfolio-data-wrapper .portfolio-data-table .header-cell[data-v-7da3ff1f] {
  color: #215796;
  background-color: #c6dcf8;
}