.footprint_container .footprint_title[data-v-22dd4fe0] {
  height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  color: #1f5080;
  font-family: "PingFang SC";
  font-weight: 900;
}
.footprint_container[data-v-22dd4fe0]  .cell {
  font-family: "PingFang SC";
  font-size: 14px;
  color: #1f5080;
}
.footprint_container .title_bg[data-v-22dd4fe0] {
  height: 40px;
  background-color: #dfebf9;
  line-height: 40px;
}
.footprint_container .text[data-v-22dd4fe0] {
  padding: 20px 16px 20px 30px;
  font-family: "PingFang SC";
  font-size: 14px;
  line-height: 20px;
  color: #1f5080;
}
.footprint_container #bar_box[data-v-22dd4fe0] {
  width: 100%;
  min-height: 120px;
}