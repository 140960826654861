.portfolio-data-table {
  width: 100%;
}
.portfolio-data-table .el-table__body-wrapper {
  overflow-x: hidden !important;
}
.portfolio-data-table .sfdr-portfolio-table-total-row {
  font-weight: 600;
  color: #01579b;
}
.portfolio-data-table .sfdr-portfolio-table-cash-row {
  background: #f8f8f8;
}