.register[data-v-77453986] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  background-image: url(../../static/img/login-background.f9f49138.jpg);
  background-size: cover;
}
.title[data-v-77453986] {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}
.register-form[data-v-77453986] {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
}
.register-form .el-input[data-v-77453986] {
  height: 38px;
}
.register-form .el-input input[data-v-77453986] {
  height: 38px;
}
.register-form .input-icon[data-v-77453986] {
  height: 39px;
  width: 14px;
  margin-left: 2px;
}
.register-tip[data-v-77453986] {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.register-code[data-v-77453986] {
  width: 33%;
  height: 38px;
  float: right;
}
.register-code img[data-v-77453986] {
  cursor: pointer;
  vertical-align: middle;
}
.el-register-footer[data-v-77453986] {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.register-code-img[data-v-77453986] {
  height: 38px;
}