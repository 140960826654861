@charset "UTF-8";
.right-table .right-table-expand[data-v-6a125560] {
  font-size: 0;
  margin-left: 25px;
}
.right-table .right-table-expand label[data-v-6a125560] {
  width: 90px;
  color: #99a9bf;
}
.right-table .right-table-expand .el-form-item[data-v-6a125560] {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.right-table .link-type[data-v-6a125560] {
  padding: 10px;
}
.right-table .link-type[data-v-6a125560]:focus,
.right-table .link-type[data-v-6a125560]:hover {
  color: #337ab7;
  cursor: pointer;
  background-color: #dfebf9;
  border-radius: 3px;
  padding: 10px;
}
.right-table .link-type.close-item[data-v-6a125560] {
  opacity: 0.5;
}
.right-table[data-v-6a125560]  .el-table__row {
  cursor: pointer;
  height: 35px;
}
.right-table[data-v-6a125560]  .el-table__body {
  width: 100% !important;
}
.right-table .right-table-title[data-v-6a125560] {
  color: #aeaeb2;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
.right-table .right-table-title[data-v-6a125560]:focus {
  color: #01579b;
}
.right-table .expond-svg[data-v-6a125560] {
  cursor: pointer;
}
.right-table .expanded .expond-svg[data-v-6a125560] {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.right-table .expanded .expond-svg *[data-v-6a125560],
.right-table .expanded .expond-svg *[data-v-6a125560]:before,
.right-table .expanded .expond-svg *[data-v-6a125560]:after {
  fill: #01579b;
}
.right-table .expanded .right-table-title[data-v-6a125560] {
  color: #01579b;
}
.right-table .expanded .right-table-title[data-v-6a125560]::after {
  content: "";
  width: 130px;
  height: 10px;
  border-bottom: 4px solid #01579b;
  /* border 位置 absolute(绝对定位) */
  position: absolute;
  left: 0;
  top: 27px;
  /* 自动内减 */
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.right-table[data-v-6a125560]  .el-table__expand-icon {
  display: none;
}
.right-table .row-style[data-v-6a125560] {
  height: 35px;
}
@media screen and (min-width: 1770px) {
.right-table[data-v-6a125560]  .el-table__row {
    height: 55px;
}
.right-table .expanded .right-table-title[data-v-6a125560]::after {
    content: "";
    top: 45px;
}
}