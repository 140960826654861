@charset "UTF-8";
.disabled-label[data-v-a68a07e2] {
  color: grey;
}
.dl-databrowser[data-v-a68a07e2] {
  padding: 20px;
  height: 100%;
}
.dl-databrowser .left-content[data-v-a68a07e2] {
  width: 300px;
  float: left;
  overflow: hidden;
  height: 100%;
  position: relative;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
  color: #01579b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dl-databrowser .left-content .resizer[data-v-a68a07e2] {
  height: 8px;
  cursor: row-resize;
  /* 设置光标样式 */
  position: relative;
  z-index: 1;
}
.dl-databrowser .left-content .card[data-v-a68a07e2] {
  min-height: 40px;
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dl-databrowser .left-content .card-top-title[data-v-a68a07e2] {
  border-radius: 6px 6px 0 0;
  line-height: 40px;
  padding-left: 10px;
  font-weight: 600;
  background-color: #dfebf9;
  font-size: 14px;
}
.dl-databrowser .left-content .card-top-title .selected-name[data-v-a68a07e2] {
  display: inline-block;
  width: calc(100% - 120px);
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-right: 20px;
  text-align: right;
  float: right;
  font-weight: 500;
}
.dl-databrowser .left-content .parent-node[data-v-a68a07e2] {
  font-weight: 700;
}
.dl-databrowser .left-content .node-content[data-v-a68a07e2] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.dl-databrowser .left-content .node-label[data-v-a68a07e2] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dl-databrowser .left-content .add-icon[data-v-a68a07e2] {
  cursor: pointer;
  color: #1890ff;
  display: none;
  font-size: 16px;
}
.dl-databrowser .left-content .node-btn[data-v-a68a07e2] {
  display: none;
}
.dl-databrowser .left-content .el-tree-node__content:hover .node-btn[data-v-a68a07e2] {
  display: block;
}
.dl-databrowser .left-content .el-tree-node__content:hover .add-icon[data-v-a68a07e2] {
  display: block;
}
.dl-databrowser .shrinkExpand[data-v-a68a07e2] {
  position: absolute;
  left: -42px;
  top: calc(50% - 25px);
  width: 60px;
  height: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 18px solid #fff;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-radius: 4px;
  -webkit-transform: translateY(calc(-50% + 27px)) rotate(90deg);
          transform: translateY(calc(-50% + 27px)) rotate(90deg);
  cursor: pointer;
  -webkit-filter: drop-shadow(0px -3px 3px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0px -3px 3px rgba(0, 0, 0, 0.1));
}
.dl-databrowser .shrinkExpand i[data-v-a68a07e2] {
  color: #01579b;
  font-weight: 500;
}
.dl-databrowser .right-content[data-v-a68a07e2] {
  background-color: #FFFFFF;
  width: calc(100% - 324px);
  height: 100%;
  float: right;
  position: relative;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.dl-databrowser .right-content .no-data[data-v-a68a07e2] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dl-databrowser .right-content .no-data .svg-icon[data-v-a68a07e2] {
  width: 400px;
  height: 400px;
}
.dl-databrowser .right-content .no-data .no-text[data-v-a68a07e2] {
  color: #909399;
  text-align: center;
  font-size: 14px;
}
.dl-databrowser .right-content .table-area[data-v-a68a07e2] {
  height: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
}
.dl-databrowser .right-content[data-v-a68a07e2]  .el-table__empty-text {
  display: none;
}
.dl-databrowser .right-content .empty-text[data-v-a68a07e2] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  line-height: 60px;
  color: #909399;
}
.dl-databrowser .el-tabs[data-v-a68a07e2] {
  height: 100%;
}
.dl-databrowser .el-tabs[data-v-a68a07e2]  .el-tabs__content {
  height: calc(100% - 55px);
}
.dl-databrowser .el-tabs[data-v-a68a07e2]  .el-tabs__content .el-tab-pane {
  height: 100%;
}
.dl-databrowser .el-tabs[data-v-a68a07e2]  .el-tabs__item.is-active {
  color: #01579b;
  font-weight: 600;
}
.dl-databrowser .popover-div[data-v-a68a07e2] {
  position: absolute;
  height: 24px;
  top: 6px;
  z-index: 1000;
}
.dl-databrowser .table-header-title[data-v-a68a07e2] {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /** 如果设置为 2 则表示只显示2行 */
  -webkit-box-orient: vertical;
  max-width: calc(100% - 20px);
}
.dl-databrowser[data-v-a68a07e2]  .el-table--border .el-table__cell {
  border-right: 0 !important;
}
.dl-databrowser[data-v-a68a07e2]  .el-table th.el-table__cell > .cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dl-databrowser .el-dropdown-link[data-v-a68a07e2] {
  cursor: pointer;
  color: #409EFF;
}
.dl-databrowser .el-dropdown-link .el-icon-arrow-down[data-v-a68a07e2] {
  font-size: 12px;
}