.additional-pai-select-wrapper[data-v-26691bd5] {
  font-family: PingFang SC-Regular;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  overflow-y: scroll;
  border-bottom: 1px solid #e3e3f0;
}
.additional-pai-select-wrapper .category-header[data-v-26691bd5] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0 12px 20px 0;
}
.additional-pai-select-wrapper .category-header .category-title[data-v-26691bd5] {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5998f0;
  line-height: 20px;
}
.additional-pai-select-wrapper .category-header .category-title .point-icon[data-v-26691bd5] {
  width: 17px;
  height: 17px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.additional-pai-select-wrapper .category-header .category-title span[data-v-26691bd5] {
  color: red;
  font-weight: 600;
}
.additional-pai-select-wrapper .category-header .select-all[data-v-26691bd5] {
  margin-left: 10px;
  padding: 2px 8px 2px 6px;
  background: rgba(235, 237, 240, 0.6);
  border-radius: 2px 2px 2px 2px;
}
.additional-pai-select-wrapper .impacts-title[data-v-26691bd5] {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  margin-bottom: 8px;
}
.additional-pai-select-wrapper .category-section[data-v-26691bd5] {
  margin-bottom: 20px;
  width: 46%;
}
.additional-pai-select-wrapper .border-split[data-v-26691bd5] {
  width: 3px;
  background: #ebedf0;
  height: 100%;
  position: relative;
  right: 6px;
  margin: 0 3%;
}
.additional-pai-select-wrapper .impacts-section[data-v-26691bd5] {
  padding: 0 0 18px;
}
.additional-pai-select-wrapper .impacts-section > div[data-v-26691bd5] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: justify;
      align-content: space-between;
}