.worldMapBoard[data-v-9620f816] {
  position: relative;
  height: 100%;
  width: 100%;
}
.worldMapBoard .worldMap[data-v-9620f816] {
  height: 100%;
  width: 100%;
}
.worldMapBoard .top-content[data-v-9620f816] {
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  z-index: 4;
}
.worldMapBoard .top-content .logo[data-v-9620f816] {
  position: absolute;
  left: 8px;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.worldMapBoard .top-content .logo img[data-v-9620f816] {
  cursor: pointer;
}
.worldMapBoard .top-content .logo .el-divider[data-v-9620f816] {
  height: 1.218rem;
  margin-right: 1.125rem;
  margin-left: 0;
  background-color: #FFF;
}
.worldMapBoard .top-content .user[data-v-9620f816] {
  position: absolute;
  right: 70px;
}
.worldMapBoard .top-content .title[data-v-9620f816] {
  position: absolute;
  font-size: 1.687rem;
  font-family: Source Han Sans SC-Regular, Source Han Sans SC;
  font-weight: 400;
  color: #323233;
}
.worldMapBoard .filter-area[data-v-9620f816] {
  position: absolute;
  top: calc(2rem + 65px);
  left: 32px;
  z-index: 4;
}
.worldMapBoard .filter-area[data-v-9620f816] .el-button--primary {
  color: #FFFFFF;
  background-color: #008FBE;
  border-color: #008FBE;
}
.worldMapBoard .legend[data-v-9620f816] {
  width: 124px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 4px 4px;
  position: absolute;
  bottom: 50px;
  right: 20px;
  padding: 16px;
  z-index: 10;
}
.worldMapBoard .legend .mark-area[data-v-9620f816] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  line-height: 30px;
  margin-left: 10px;
}
.worldMapBoard .legend .mark-area .mark[data-v-9620f816] {
  width: 20px;
  height: 6px;
  margin-right: 8px;
  background-color: #C8C9CC;
  opacity: 0.8;
}
.worldMapBoard .legend .linear-gradient[data-v-9620f816] {
  background: -webkit-gradient(linear, left bottom, left top, from(#ffff09), to(#bb0909));
  background: linear-gradient(to top, #ffff09, #bb0909);
  height: 133px;
  width: 20px;
  margin: 8px 0 12px 8px;
}
.worldMapBoard .legend .level[data-v-9620f816] {
  height: 138px;
  position: absolute;
  top: 40px;
  font-size: 12px;
  left: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.worldMapBoard .bottom-area[data-v-9620f816] {
  position: absolute;
  bottom: 0;
  height: 24px;
  width: 100%;
  background: #FFFFFF;
  opacity: 0.8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 100;
}
.worldMapBoard .bottom-area a[data-v-9620f816] {
  font-size: 12px;
  font-weight: 400;
  color: #008FBE;
  margin: 0 12px;
  text-decoration: underline;
}
.worldMapBoard .location-detail[data-v-9620f816] {
  width: 320px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #008FBE;
  z-index: 100;
}
.worldMapBoard .location-detail .detail-top[data-v-9620f816] {
  background-color: #008FBE;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 0 10px 8px;
  line-height: 20px;
  color: #fff;
}
.worldMapBoard .location-detail .el-icon-close[data-v-9620f816] {
  font-size: 16px;
  position: absolute;
  right: 8px;
  top: 14px;
  cursor: pointer;
}
.worldMapBoard .location-detail .radar .risk-name[data-v-9620f816] {
  float: right;
  padding: 2px 6px;
  margin: 16px;
  background-color: #C8C9CC;
  color: #FFF;
  font-size: 12px;
  opacity: 0.8;
}
.worldMapBoard .location-detail .radar .echart[data-v-9620f816] {
  width: 280px;
  height: 240px;
}