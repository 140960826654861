.abnormal-target[data-v-054db0cb] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.abnormal-target .left-tree[data-v-054db0cb] {
  height: 540px;
  overflow-y: auto;
  background-color: #fff;
}
.abnormal-target .img-box[data-v-054db0cb] {
  display: inline-block;
  border: 1px solid #323233;
}
.abnormal-target .img-box .img-top-title[data-v-054db0cb] {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #323233;
}
.abnormal-target .img-box .img-top-title .title-content[data-v-054db0cb] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
  line-height: 38px;
}
.abnormal-target .img-box .img-top-title i[data-v-054db0cb] {
  line-height: 38px;
  width: 20px;
  cursor: pointer;
}
.abnormal-target .img-box .image[data-v-054db0cb] {
  height: 110px;
  width: 260px;
  cursor: pointer;
  margin: 5px;
}