.el-menu {
  background-color: #01579b;
}
.topmenu-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.topmenu-container .el-arrow-white {
  display: inline-block;
  margin-left: 4px;
}
.topmenu-container .el-arrow-white .svg-icon {
  width: 0.7em;
}
.el-menu--horizontal > .el-menu-item {
  height: 100%;
  line-height: 30px !important;
  color: #fff !important;
  border-bottom: unset;
  margin-left: 5px;
}
.menu-no-child {
  padding: 0 10px;
}
.topmenu-container.el-menu--horizontal > .el-menu-item.is-active,
.topmenu-container.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  background-color: #2e74b9 !important;
  border-bottom: unset;
  border-radius: 4px;
  padding: 0 10px;
}

/* submenu item */
.topmenu-container.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #fff !important;
}
.topmenu-container .el-menu-item:hover {
  background-color: #2e74b9 !important;
  border-radius: 4px;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: unset;
}
.el-menu.el-menu--horizontal {
  border: unset;
}
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #2e74b9 !important;
  border-radius: 4px;
}
.el-submenu.is-opened > .el-submenu__title .el-arrow-white {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.el-menu--horizontal {
  z-index: 20001 !important;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #191919;
  line-height: 40px;
  height: 40px;
}
.el-menu--horizontal .el-menu .el-menu-item.dropdown-li.is-active,
.el-menu--horizontal .el-menu .el-menu-item.dropdown-li:hover,
.el-menu--horizontal .el-menu-item.dropdown-li:focus {
  background-color: #DFEBF9;
  color: #14487b !important;
}
.el-menu-item.dropdown-li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.el-menu-item.dropdown-li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.el-menu--popup {
  padding: unset;
  border-radius: 8px;
}
.el-menu--horizontal > .dropdown-container.el-submenu .el-submenu__title {
  line-height: 30px !important;
  height: unset;
}
.dropdown-container.el-submenu [class^=el-icon-] {
  margin-right: unset !important;
  width: unset !important;
}