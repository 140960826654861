.quarter-range-picker {
  display: inline-block;
  position: relative;
}
.quarter-range-picker .el-card__body {
  padding: 0;
}
.quarter-range-picker .quarter-range-picke__value {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.quarter-range-picker__box-card {
  margin-top: 10px;
  position: absolute;
  z-index: 9999;
}
.quarter-range-picker__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.quarter-range-picker-date-popper {
  display: none;
}