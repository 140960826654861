.fileTable[data-v-65e064be]  .tableHead th {
  font-family: "PingFang SC";
  font-weight: 900;
  height: 70px;
  font-size: 14px;
  color: #1f5080;
  background: #dfebf9;
}
.fileTable[data-v-65e064be]  .cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  height: 40px;
  line-height: 20px;
  font-family: "PingFang SC";
  font-size: 14px;
  color: #1f5080;
}
.fileTable[data-v-65e064be]  th .cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 42px;
}
.pagination[data-v-65e064be] {
  margin: 10px 10px 30px 0;
  text-align: right;
}
[data-v-65e064be] .el-pager li {
  font-weight: 400;
}
.text[data-v-65e064be] {
  padding: 16px 30px 0px 30px;
  font-family: "PingFang SC";
  font-size: 14px;
  line-height: 20px;
  color: #1f5080;
}