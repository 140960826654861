@charset "UTF-8";
/*覆盖样式*/
.page-show-vue[data-v-52ae9d7f] {
  height: 100%;
}
.page-show-vue .markdown-body[data-v-52ae9d7f] {
  height: calc(100vh - 270px);
  overflow-y: auto;
}
@media screen and (min-width: 1770px) {
.page-show-vue .markdown-body[data-v-52ae9d7f] {
    height: calc(100vh - 400px);
}
}
.page-show-vue .icon-collapse[data-v-52ae9d7f] {
  float: left;
  font-size: 25px;
  color: #aaa;
  margin-top: 8px;
  cursor: pointer;
}
.page-show-vue .icon-collapse[data-v-52ae9d7f]:hover {
  color: #eee;
}
.page-show-vue .wiki-title[data-v-52ae9d7f] {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.page-show-vue .dl-page-content img[data-v-52ae9d7f] {
  cursor: pointer;
  max-width: 100%;
}
.page-show-vue .dl-page-content img[data-v-52ae9d7f]:hover {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}
.page-show-vue .upload-page-file .el-upload-list[data-v-52ae9d7f] {
  display: none;
}
.page-show-vue .is-link[data-v-52ae9d7f] {
  color: #1e88e5;
  cursor: pointer;
}

/*编辑框高度*/