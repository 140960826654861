.instructions-box[data-v-897a7660]  .el-table .warning-row {
  background: #f7f8fb;
}
.instructions-box[data-v-897a7660]  .el-table th.el-table__cell.is-leaf,
.instructions-box[data-v-897a7660]  .el-table td.el-table__cell {
  border: none;
}
.instructions-box[data-v-897a7660]  .el-table .el-table__body-wrapper {
  height: calc(100vh - 260px);
  width: 100%;
  overflow: auto;
}
.instructions-box[data-v-897a7660]  .el-table__body {
  height: 100%;
}
.instructions-box[data-v-897a7660]  .el-table td.el-table__cell.dashed_column {
  border-right: 1px dashed #e7e7e8;
}
.instructions-box[data-v-897a7660]  .el-table td.el-table__cell.dashe_row {
  border-top: 1px dashed #e7e7e8;
}
.instructions-box[data-v-897a7660]  .el-table th:nth-last-child(2) .cell {
  padding-left: 0;
  padding-right: 0;
}
.instructions-box .el-icon-ins .svg-icon[data-v-897a7660] {
  width: 0.8em;
}