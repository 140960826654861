#bar_box1[data-v-0320f4bb] {
  width: 100%;
  min-height: 120px;
}
.risk_container .footprint_title[data-v-0320f4bb] {
  height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  color: #1F5080;
  font-family: "PingFang SC";
  font-weight: 900;
}
.risk_container .title_bg[data-v-0320f4bb] {
  height: 40px;
  background-color: #DFEBF9;
  line-height: 40px;
}
.risk_container .text[data-v-0320f4bb] {
  padding: 16px 30px 16px 30px;
  font-family: "PingFang SC";
  font-size: 14px;
  line-height: 20px;
  color: #1F5080;
}
.risk_container .text_mid[data-v-0320f4bb] {
  padding: 16px 30px 16px 30px;
}
.risk_container .text_mid p[data-v-0320f4bb] {
  margin-bottom: 10px;
}
.risk_container .text_mid p[data-v-0320f4bb]:last-child {
  margin-bottom: 0;
}
.risk_container .title_mid[data-v-0320f4bb] {
  height: 40px;
  line-height: 40px;
  border-top: 1px solid #D9D9D9;
  border-left: 1px dashed #D9D9D9;
  border-right: 1px dashed #D9D9D9;
  background: #F7F8FB;
}
.risk_container .text_bottom[data-v-0320f4bb] {
  margin: 20px 10px;
  font-family: "PingFang SC";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1F5080;
}
.risk_container .table[data-v-0320f4bb] {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px dashed #D9D9D9;
  border-right: 1px dashed #D9D9D9;
}
.risk_container .table .bg[data-v-0320f4bb] {
  background-color: #F7F8FB;
}
.risk_container .table td[data-v-0320f4bb] {
  height: 40px;
  border-left: 1px dashed #D9D9D9;
  border-top: 1px dashed #D9D9D9;
  padding: 10px 20px;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  line-height: 20px;
  color: #1F5080;
}
.risk_container .table td[data-v-0320f4bb]:first-child {
  width: 180px;
}
.risk_container .table td[data-v-0320f4bb]:nth-child(2) {
  width: 190px;
}
.risk_container .table td[data-v-0320f4bb]:nth-child(3) {
  width: 164px;
}
.risk_container .table .red[data-v-0320f4bb] {
  width: 110px;
  height: 40px;
  margin: 0 auto;
  background: #F07B31;
  border-radius: 6px;
  font-family: "PingFang SC";
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
  color: #ffffff;
}
.risk_container .table .green[data-v-0320f4bb] {
  width: 110px;
  height: 40px;
  margin: 0 auto;
  background: #AED568;
  border-radius: 6px;
  font-family: "PingFang SC";
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
  color: #1F5080;
}