.review-menu[data-v-29694a61] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}
.review-menu .menu-group[data-v-29694a61] {
  padding-bottom: 8px;
}
.review-menu .menu-group .menu-icon[data-v-29694a61] {
  width: 16px;
  height: 16px;
  position: relative;
  top: 3px;
}
.review-menu .menu-group .menu-item[data-v-29694a61] {
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  text-align: left;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #215796;
  line-height: 20px;
  -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}
.review-menu .menu-group .menu-item[data-v-29694a61]:hover {
  outline: none;
  background-color: #ecf5ff;
}
.review-menu .menu-group .menu-group-title[data-v-29694a61] {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #215796;
  line-height: 20px;
}
@-webkit-keyframes fadeBackground-data-v-29694a61 {
from {
    background-color: transparent;
}
to {
    background-color: #dae8f7;
}
}
@keyframes fadeBackground-data-v-29694a61 {
from {
    background-color: transparent;
}
to {
    background-color: #dae8f7;
}
}
.review-menu .selected[data-v-29694a61] {
  background: #dae8f7;
  color: #fff;
  border-radius: 2px 2px 2px 2px;
  -webkit-animation: fadeBackground-data-v-29694a61 0.8s 1;
          animation: fadeBackground-data-v-29694a61 0.8s 1;
}