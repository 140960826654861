.mandatory-data-wrapper[data-v-56694665] {
  font-family: PingFang SC-Regular;
  height: calc(100% - 113px);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.mandatory-data-wrapper .mandatory-data-table[data-v-56694665] {
  height: calc(100%);
}
.mandatory-data-wrapper .mandatory-data-table[data-v-56694665]  .header-cell {
  color: #2f5584;
  background-color: #e0ecfa;
  font-size: 13px;
  font-weight: 400;
  color: #215796;
  background-color: #e1ebf8;
  font-family: PingFang SC-Regular, PingFang SC;
}
.mandatory-data-wrapper .mandatory-data-table[data-v-56694665]  .normal-cell-class div {
  word-break: break-word;
}
.mandatory-data-wrapper .mandatory-data-table[data-v-56694665]  .drill-down-cell:hover div {
  word-break: break-word;
  color: #01579b;
  cursor: pointer;
  text-decoration: underline;
}