.quarter-picker__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 12px;
  padding: 8px 16px;
  border-bottom: 1px solid #e5e6eb;
}
.quarter-picker__header .el-icon-d-arrow-left,
.quarter-picker__header .el-icon-d-arrow-right,
.quarter-picker__header .empty-arrow {
  cursor: pointer;
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  transition: all 0.1s cubic-bezier(0, 0, 1, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.quarter-picker__header .el-icon-d-arrow-left:hover,
.quarter-picker__header .el-icon-d-arrow-right:hover,
.quarter-picker__header .empty-arrow:hover {
  background-color: #e5e6eb;
}
.quarter-picker__header .empty-arrow {
  cursor: auto;
}
.quarter-picker__header .empty-arrow:hover {
  background-color: transparent;
}
.quarter-picker__header .quarter-picker__title {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  color: #1d2129;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}
.quarter-picker__options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 14px 16px;
}
.quarter-picker__item {
  padding: 4px;
  cursor: pointer;
  position: relative;
}
.quarter-picker__item__label {
  border-radius: 24px;
  color: #000;
  background-color: #fff;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  width: 64px;
}
.quarter-picker__item--active .quarter-picker__item__label,
.quarter-picker__item:hover .quarter-picker__item__label {
  background-color: #1890ff;
  color: #fff;
}
.quarter-picker__item__today:after {
  position: absolute;
  bottom: -2px;
  left: 50%;
  display: block;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  background-color: #1890ff;
  border-radius: 50%;
  content: "";
}
.quarter-picker__item__disabled {
  background-color: #f5f7fa !important;
  cursor: not-allowed;
  color: #c0c4cc !important;
}