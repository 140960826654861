.app-container[data-v-e5e7f8e2] {
  overflow: unset;
}
.app-main[data-v-e5e7f8e2] {
  min-height: unset;
}
.outer-box[data-v-e5e7f8e2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
}
.outer-box .left-box[data-v-e5e7f8e2] {
  max-height: calc(100vh - 220px);
  margin-right: 20px;
  margin-top: 50px;
}
.outer-box .left-box .c-tree[data-v-e5e7f8e2] {
  height: calc(100vh - 215px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.outer-box .left-box .tree-list[data-v-e5e7f8e2],
.outer-box .left-box .tree-last-list[data-v-e5e7f8e2] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: hidden;
  margin-bottom: 5px;
  padding-bottom: 42px;
}
.outer-box .tree-bottom-input[data-v-e5e7f8e2] {
  padding: 10px 0;
  position: fixed;
  bottom: 58px;
  width: 245px;
}
.outer-box .tree-bottom-input .el-input__inner[data-v-e5e7f8e2] {
  width: 600px;
}
[data-v-e5e7f8e2] .el-table__body-wrapper .is-scrolling-right,[data-v-e5e7f8e2] .el-table__body-wrapper .is-scrolling-left,[data-v-e5e7f8e2] .el-table__body-wrapper .is-scrolling-none {
  height: 100% !important;
}
.content[data-v-e5e7f8e2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: calc(100vh - 140px);
  position: relative;
  width: 100%;
}
.content[data-v-e5e7f8e2]  .el-table {
  border-radius: 6px;
  max-width: calc(100vw - 300px);
  max-height: calc(100vh - 175px);
}
.content .out-div[data-v-e5e7f8e2] {
  height: 100%;
}
.content[data-v-e5e7f8e2]  .el-table__body-wrapper.is-scrolling-none ~ .el-table__fixed {
  -webkit-box-shadow: revert;
          box-shadow: revert;
  border-right: 1px dashed #e7e7e8;
  max-height: calc(100vh - 175px);
}
.content .c-list[data-v-e5e7f8e2] {
  height: 100%;
}
.content .c-list[data-v-e5e7f8e2]  .el-table .el-table__header-wrapper th,
.content .c-list[data-v-e5e7f8e2]  .el-table .el-table__fixed-header-wrapper th,
.content .c-list[data-v-e5e7f8e2]  .el-table__fixed-right-patch,
.content .c-list[data-v-e5e7f8e2]  .el-table__header-wrapper {
  background-color: #dfebf9;
}
.content .c-list[data-v-e5e7f8e2]  .el-table .el-table__fixed-header-wrapper th,
.content .c-list[data-v-e5e7f8e2]  .el-table__fixed {
  color: #1f5080;
}
.content .c-list[data-v-e5e7f8e2]  .el-table__fixed::before,
.content .c-list[data-v-e5e7f8e2]  .el-table__fixed-right::before {
  content: unset;
}
.content .c-list[data-v-e5e7f8e2]  .el-table__empty-block {
  width: 100% !important;
}
.content .c-list .el-alert--warning.is-light[data-v-e5e7f8e2],
.content .c-list .el-alert--success.is-light[data-v-e5e7f8e2] {
  background-color: transparent;
  color: #676878;
}
.content .c-list .el-alert.lazy-alert[data-v-e5e7f8e2] {
  padding: 3px 16px;
  font-size: 12px;
}
.content .c-list .el-alert.lazy-alert[data-v-e5e7f8e2]  .el-alert__closebtn {
  display: none;
}
.content .c-list[data-v-e5e7f8e2]  .el-table--border th.el-table__cell.gutter:last-of-type {
  border-bottom: unset;
  border-bottom-width: unset;
}
.content .c-list[data-v-e5e7f8e2]  .el-table tr {
  height: 40px;
}
.content .c-list[data-v-e5e7f8e2]  .el-table--medium .el-table__cell,
.content .c-list[data-v-e5e7f8e2]  .el-table .el-table__cell {
  padding: unset;
}
.content .c-list .dynamic-indicator-code[data-v-e5e7f8e2],
.content .c-list .dynamic-indicator-other[data-v-e5e7f8e2] {
  display: inline;
}
.content[data-v-e5e7f8e2]  .table_DB .warning-row {
  background: #f7f8fb;
}
.dialog-box .key-world-dialog[data-v-e5e7f8e2] {
  position: fixed;
  top: unset;
  left: 20px;
  bottom: 137px;
  right: 0;
  width: 400px;
  height: 190px;
  overflow: unset;
  background-color: unset;
}
.dialog-box[data-v-e5e7f8e2]  .el-dialog__body {
  padding: 10px;
  overflow-y: auto;
}
.dialog-box[data-v-e5e7f8e2]  .el-dialog__header {
  padding: 10px;
}
.dialog-box[data-v-e5e7f8e2]  .el-dialog__headerbtn {
  top: 4px;
  right: 10px;
}
.dialog-box[data-v-e5e7f8e2]  .el-table--medium .el-table__cell {
  padding: 3px 0;
}
.el-dialog__wrapper.dialog_DB[data-v-e5e7f8e2] {
  top: 50px;
}
.dialog_DB[data-v-e5e7f8e2]  .el-dialog__body {
  padding: 30px 50px;
}
.dialog_DB[data-v-e5e7f8e2]  .el-message-box__content {
  padding: 10px 25px;
}
.dialog_DB .dialog-footer[data-v-e5e7f8e2] {
  width: 360px;
  margin: 0 auto 20px auto;
  text-align: center;
}
.dialog_DB .dialog-footer button[data-v-e5e7f8e2] {
  width: 170px;
}
.dialog_DB .dialog-footer button.right_btn[data-v-e5e7f8e2] {
  margin-left: 20px;
}
.dialog_DB[data-v-e5e7f8e2]  .el-upload-dragger {
  background: -webkit-gradient(linear, left bottom, left top, from(#f4f9ff), to(rgba(223, 235, 249, 0)));
  background: linear-gradient(0deg, #f4f9ff 0%, rgba(223, 235, 249, 0) 100%);
}
@media screen and (min-width: 1790px) {
.content[data-v-e5e7f8e2] {
    max-height: calc(100vh - 180px);
}
.content .c-list[data-v-e5e7f8e2]  .el-table {
    max-width: calc(100vw - 335px);
}
.tree-bottom-input[data-v-e5e7f8e2] {
    width: 275px;
}
}
@media screen and (min-width: 1300px) {
.dialog-box .key-world-dialog[data-v-e5e7f8e2] {
    position: fixed;
    bottom: 162px;
}
}