.fileTable[data-v-18dba99a]  .tableHead th {
  font-family: "PingFang SC";
  font-weight: 900;
  height: 40px;
  font-size: 14px;
  color: #1F5080;
  background: #DFEBF9;
}
.fileTable[data-v-18dba99a]  .cell {
  font-family: "PingFang SC";
  font-size: 14px;
  color: #1F5080;
}
.text[data-v-18dba99a] {
  padding: 36px 40px 16px 40px;
  font-family: "PingFang SC";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1F5080;
}
.pagination[data-v-18dba99a] {
  margin: 10px 10px 30px 0;
  text-align: right;
}
[data-v-18dba99a] .el-pager li {
  font-weight: 400;
}