.welcome[data-v-758b0cb6] {
  position: absolute;
  top: 53px;
  left: 16px;
  right: 16px;
  bottom: 15px;
  z-index: 99;
  background: #fff;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow-y: auto;
  overflow-x: auto;
}
.welcome .title-img[data-v-758b0cb6] {
  width: 816px;
  height: 144px;
  position: absolute;
  left: 40px;
  top: 16px;
}
.welcome .background[data-v-758b0cb6] {
  z-index: 1;
  margin-top: 88px;
}
.welcome .background .background-bottom[data-v-758b0cb6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 20px;
}
.welcome .background .background-bottom .text[data-v-758b0cb6] {
  z-index: 2;
  max-width: 642px;
  min-width: 642px;
}
.welcome .background .background-bottom .history-img[data-v-758b0cb6] {
  width: 380px;
  height: 230px;
  margin-left: 130px;
  position: relative;
  right: 58px;
  bottom: 37px;
}
.welcome .steps[data-v-758b0cb6] {
  margin-top: 56px;
}
.welcome .steps .steps-bottom[data-v-758b0cb6] {
  width: 1156px;
  height: 168px;
  background: #f5f9ff;
  margin-top: 20px;
  padding: 16px 25px 0 25px;
}
.welcome .steps .steps-bottom .background-line[data-v-758b0cb6] {
  position: relative;
  z-index: 1;
  top: 12px;
  width: 90%;
  margin: auto;
  height: 2px;
  background: #01579b;
}
.welcome .steps .steps-bottom .contents[data-v-758b0cb6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.welcome .steps .steps-bottom .contents .item[data-v-758b0cb6] {
  position: relative;
  z-index: 2;
}
.welcome .steps .steps-bottom .contents .item .icon-container[data-v-758b0cb6] {
  width: 72px;
  background: #f5f9ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.welcome .steps .steps-bottom .contents .item .icon[data-v-758b0cb6] {
  width: 24px;
  height: 24px;
  background: #01579b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  margin-bottom: 16px;
}
.welcome .steps .steps-bottom .contents .item .icon i[data-v-758b0cb6] {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.welcome .steps .steps-bottom .contents .item .number[data-v-758b0cb6] {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  color: #3d3d3d;
}
.welcome .steps .steps-bottom .contents .item .text[data-v-758b0cb6] {
  width: 100px;
  max-height: 60px;
  text-align: center;
}
.welcome .enter-button[data-v-758b0cb6] {
  width: 96px;
  height: 40px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.flex-center[data-v-758b0cb6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-start[data-v-758b0cb6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-auto[data-v-758b0cb6] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flex-column[data-v-758b0cb6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.flex-column-center[data-v-758b0cb6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}