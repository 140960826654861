.instructions[data-v-57613bf1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 53px;
  left: 16px;
  right: 16px;
  bottom: 15px;
  z-index: 100;
}
.instructions .header[data-v-57613bf1] {
  background: #e1ebf8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 44px;
  padding: 0 10px 0 18px;
}
.instructions .header .header-title[data-v-57613bf1] {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #215796;
  line-height: 20px;
}
.instructions .header i[data-v-57613bf1] {
  cursor: pointer;
  color: #5998f0;
  font-size: 18px;
  width: 26px;
  height: 22px;
  text-align: center;
}
.instructions .body[data-v-57613bf1] {
  background: #ffffff;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.instructions .body .row[data-v-57613bf1] {
  height: 210px;
}
.instructions .body .left[data-v-57613bf1] {
  width: 318px;
  height: 100%;
}
.instructions .body .right[data-v-57613bf1] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.instructions .body .right .info-row[data-v-57613bf1] {
  height: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.instructions .body .right .info-row div[data-v-57613bf1] {
  height: 100%;
}
.instructions .body .right .number[data-v-57613bf1] {
  width: 100px;
}
.instructions .body .right .text[data-v-57613bf1] {
  width: calc(100% - 100px);
  padding-left: 20px;
  border-right: none;
}
.grey[data-v-57613bf1] {
  background: #fafafa;
}
.flex-center[data-v-57613bf1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-start[data-v-57613bf1] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-auto[data-v-57613bf1] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.border[data-v-57613bf1] {
  border-right: 1px dashed #eee;
  border-bottom: 1px dashed #eee;
}