.company-detail[data-v-0a72d601] {
  width: 100%;
}
.font-32[data-v-0a72d601] {
  font-size: 32px;
  font-weight: bolder;
}
.font-26[data-v-0a72d601] {
  font-size: 26px;
  font-weight: 700;
}
.font-24[data-v-0a72d601] {
  font-size: 22px;
  font-weight: 600;
  white-space: pre;
}
.dialog-box[data-v-0a72d601]  .el-dialog {
  margin-top: 90px !important;
}
.dialog-box .dialog-text[data-v-0a72d601] {
  text-indent: 2em;
  max-height: calc(100vh - 350px);
  overflow: auto;
}