
.navigation {
  width: 100%;
}
.navigation-heading {
  /* position: fixed;
  z-index: 4;
  top: 250px; */
  max-height: calc(100vh - 290px);
  width: 100%;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.navigation-heading .heading-item {
  padding: 5px 0;
  cursor: pointer;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.navigation-heading .heading-item:hover {
  color: #3370ff;
}
.navigation-heading .heading-1 {
  padding-left: 0;
}
.navigation-heading .heading-2 {
  padding-left: 16px;
}
.navigation-heading .heading-3 {
  padding-left: 32px;
}
.navigation-heading .heading-4 {
  padding-left: 48px;
}
.navigation-heading .heading-5 {
  padding-left: 64px;
}
.navigation-heading .heading-6 {
  padding-left: 80px;
}
@media screen and (min-width: 1770px) {
.navigation-heading {
    max-height: calc(100vh - 430px);
}
}
