.api-edit-drawer .api-content[data-v-409bc6f9] {
  padding: 0 20px 64px 20px;
}
.api-edit-drawer .api-content .sub-title[data-v-409bc6f9] {
  font-size: 24px;
  margin-bottom: 16px;
}
.edit-mode[data-v-409bc6f9] {
  margin: 16px 0;
  overflow: hidden;
}
.edit-mode .third-title[data-v-409bc6f9] {
  margin: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.left-content[data-v-409bc6f9] {
  width: 360px;
  float: left;
  height: 1200px;
  border: 1px solid #e4e7ed;
}
.left-content .tree-data[data-v-409bc6f9] {
  padding: 10px;
  height: 50%;
}
.right-content[data-v-409bc6f9] {
  float: right;
  width: calc(100% - 380px);
}
.custom-tree-node[data-v-409bc6f9] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.custom-tree-node .node-label[data-v-409bc6f9] {
  width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-tree-node .node-btn[data-v-409bc6f9] {
  display: none;
}
.el-tree-node__content:hover .node-btn[data-v-409bc6f9] {
  display: block;
}
.footer-btn[data-v-409bc6f9] {
  padding: 16px 20px 16px 0;
  text-align: right;
  position: absolute;
  bottom: 0;
  padding: 16px;
  background-color: #FFFFFF;
  z-index: 100;
  width: 100%;
}
.node-mark[data-v-409bc6f9] {
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  margin-right: 4px;
  background-color: #01579b;
  color: #FFFFFF;
  float: left;
}
.table-form .el-form-item[data-v-409bc6f9] {
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0px;
}
.table-form .el-form-item__content[data-v-409bc6f9] {
  width: 100%;
}
.el-tag + .el-tag[data-v-409bc6f9] {
  margin-left: 10px;
}
.button-new-tag[data-v-409bc6f9] {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag[data-v-409bc6f9] {
  width: 120px;
  margin-left: 10px;
  vertical-align: bottom;
}
.columnOutputTitle-item[data-v-409bc6f9]  .el-form-item__content {
  width: 100%;
}
[data-v-409bc6f9] .cell-overflow .cell {
  overflow: visible;
}