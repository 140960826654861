.account-center[data-v-41cb8f22] {
  height: calc(100vh - 120px);
  padding: 20px;
}
.account-center div[data-v-41cb8f22],
.account-center .head[data-v-41cb8f22],
.account-center[data-v-41cb8f22]  .el-form-item__label {
  color: #01579b;
  font-weight: 500;
}
.account-center .head[data-v-41cb8f22] {
  font-weight: 700;
}
.account-center .nick-name[data-v-41cb8f22] {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}
.account-center .box-card[data-v-41cb8f22],
.account-center .user-info[data-v-41cb8f22],
.account-center .carbon-data[data-v-41cb8f22] {
  height: calc(100vh - 170px);
}
.account-center[data-v-41cb8f22]  .el-card__body {
  padding: 8px;
}
.account-center .user-info[data-v-41cb8f22] {
  margin: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.account-center .user-info[data-v-41cb8f22]  .el-form-item {
  margin-bottom: unset;
}
.account-center .user-info .user-info-top[data-v-41cb8f22],
.account-center .user-info .user-info-middle[data-v-41cb8f22] {
  margin-bottom: 10px;
}
.account-center .user-info .user-info-top[data-v-41cb8f22]  .el-form-item .el-form-item__label,
.account-center .user-info .user-info-top[data-v-41cb8f22]  .el-form-item .el-form-item__content,
.account-center .user-info .user-info-bottom[data-v-41cb8f22]  .el-form-item .el-form-item__label,
.account-center .user-info .user-info-bottom[data-v-41cb8f22]  .el-form-item .el-form-item__content,
.account-center .user-info .user-info-middle[data-v-41cb8f22]  .el-form-item .el-form-item__label,
.account-center .user-info .user-info-middle[data-v-41cb8f22]  .el-form-item .el-form-item__content {
  line-height: 25px;
}
.account-center .user-info .user-info-top .links[data-v-41cb8f22],
.account-center .user-info .user-info-bottom .links[data-v-41cb8f22],
.account-center .user-info .user-info-middle .links[data-v-41cb8f22] {
  font-size: 14px;
  margin-right: 20px;
}
.account-center .user-info .user-info-top[data-v-41cb8f22],
.account-center .user-info .user-info-middle[data-v-41cb8f22] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.account-center .user-info .list-group[data-v-41cb8f22] {
  margin: 0;
}
.account-center .user-info .list-group-item[data-v-41cb8f22] {
  margin: unset;
  border-bottom: unset;
  border-top: unset;
}
.account-center .box-card[data-v-41cb8f22] {
  border: unset;
  background-color: #01579b;
  background-image: url(../../static/img/avat-bg.55dbbca3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.account-center .left-avatar[data-v-41cb8f22] {
  margin-top: 80px;
}
@media (max-width: 768px) {
.account-center .user-info[data-v-41cb8f22] {
    margin: unset;
}
.account-center .el-col-middle[data-v-41cb8f22] {
    margin: 10px 0;
}
}
@media screen and (min-width: 1770px) {
.account-center[data-v-41cb8f22] {
    padding: 80px 20px;
    height: calc(100vh - 300px);
}
.account-center .user-info .user-info-top .el-form-item[data-v-41cb8f22]  .el-form-item__label,
.account-center .user-info .user-info-top .el-form-item[data-v-41cb8f22]  .el-form-item__content,
.account-center .user-info .user-info-bottom .el-form-item[data-v-41cb8f22]  .el-form-item__label,
.account-center .user-info .user-info-bottom .el-form-item[data-v-41cb8f22]  .el-form-item__content,
.account-center .user-info .user-info-middle .el-form-item[data-v-41cb8f22]  .el-form-item__label,
.account-center .user-info .user-info-middle .el-form-item[data-v-41cb8f22]  .el-form-item__content {
    line-height: 60px;
}
.account-center .box-card[data-v-41cb8f22],
.account-center .user-info[data-v-41cb8f22],
.account-center .carbon-data[data-v-41cb8f22] {
    height: calc(100vh - 300px);
}
}