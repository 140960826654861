.admin-center-page[data-v-0418c869] {
  padding: 20px;
  background-color: #FFFFFF;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.admin-center-page .page-title[data-v-0418c869] {
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e7ed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.admin-center-page .table-area[data-v-0418c869] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 0;
}