.about-data[data-v-06800bfa] {
  background-color: #F7F8FA;
}
.about-data *[data-v-06800bfa] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Noto Sans SC, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}
.about-data .section_title[data-v-06800bfa] {
  text-align: center;
  padding: 8px 0 8px;
}
.about-data .section_title span[data-v-06800bfa] {
  font-size: 36px;
  color: #fff;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  margin: 0 16px;
  vertical-align: middle;
}
.about-data .section .container[data-v-06800bfa] {
  width: 1200px;
  margin: 0 auto;
  padding: 8px 20px;
}