.upload-dialog[data-v-0d359922] {
  padding-top: 5vh;
  font-family: PingFang SC-Regular;
}
.upload-dialog .download-template[data-v-0d359922] {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #215796;
  line-height: 20px;
}
.upload-dialog .notes-container[data-v-0d359922] {
  margin-top: 20px;
}
.upload-dialog .notes[data-v-0d359922] {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  opacity: 0.8;
  text-align: left;
  line-height: 20px;
  word-break: normal;
  white-space: pre-wrap;
  word-wrap: break-word;
  -webkit-text-fill-color: #606266;
}
.upload-dialog .dialog-footer[data-v-0d359922] {
  width: 360px;
  margin: 0 auto 20px auto;
  text-align: center;
}
.upload-dialog .dialog-footer button[data-v-0d359922] {
  width: 170px;
}
.upload-dialog .dialog-footer button.right_btn[data-v-0d359922] {
  margin-left: 20px;
}
.upload-dialog[data-v-0d359922]  .el-upload-dragger {
  background: -webkit-gradient(linear, left bottom, left top, from(#f4f9ff), to(rgba(223, 235, 249, 0)));
  background: linear-gradient(0deg, #f4f9ff 0%, rgba(223, 235, 249, 0) 100%);
}
.upload-dialog[data-v-0d359922]  .el-dialog__body {
  padding: 25px 50px 0px;
}
.upload-dialog[data-v-0d359922]  .el-upload__tip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}