.footer[data-v-ff148386] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 6px 0 16px;
}
.footer .warning-message[data-v-ff148386] {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ed6a0c;
  line-height: 16px;
  width: 100%;
  margin-bottom: 12px;
}
.footer .btn-container[data-v-ff148386] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.footer[data-v-ff148386] {
  padding: 16px 0;
}
.mandatory-page[data-v-ff148386] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: calc(100% - 214px);
}
.mandatory-page .mandatory-header[data-v-ff148386] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 14px;
}
.mandatory-page .mandatory-header .impact-select-wrapper span[data-v-ff148386] {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  margin-right: 8px;
}
.mandatory-page .mandatory-header .impact-select-wrapper .impact-select[data-v-ff148386] {
  width: 250px;
  border-color: transparent;
}
.mandatory-page .mandatory-header .impact-select-wrapper .impact-select[data-v-ff148386]  .el-input__inner {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.mandatory-page .mandatory-header .display-select-wrapper[data-v-ff148386]  .el-radio.is-checked .el-radio__inner {
  background: #ffffff;
}
.mandatory-page .mandatory-header .display-select-wrapper[data-v-ff148386]  .el-radio.is-checked .el-radio__inner::after {
  width: 6px;
  height: 6px;
  background: #1890ff;
}
.mandatory-page .mandatory-header .display-select-wrapper[data-v-ff148386]  .el-radio.is-checked .el-radio__label {
  color: #606266;
}
.mandatory-page .mandatory-header .display-select-wrapper .el-radio[data-v-ff148386]:first-child {
  margin-right: 22px;
}