[data-v-2e67d8ac] .popper__arrow {
  display: none !important;
}
.set-lang[data-v-2e67d8ac]  .el-button--primary {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  color: #384fbe;
  height: 30px;
  width: 82px;
  padding: 5px 10px;
}
.set-lang .el-button--primary[data-v-2e67d8ac]:hover, .set-lang .el-button--primary[data-v-2e67d8ac]:focus {
  border: 1px solid #384fbe;
}
.set-lang .el-dropdown-menu__item[data-v-2e67d8ac]:not(.is-disabled):hover,
.set-lang .el-dropdown-menu__item[data-v-2e67d8ac]:focus {
  width: 100%;
  background-color: #dfebf9;
  color: #14487b !important;
}
.set-lang[data-v-2e67d8ac]  .el-dropdown-menu--medium {
  padding: 0px;
}
.set-lang .el-dropdown-menu__item[data-v-2e67d8ac]:not(.is-disabled):hover:first-child,
.set-lang .el-dropdown-menu__item[data-v-2e67d8ac]:focus:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.set-lang .el-dropdown-menu__item[data-v-2e67d8ac]:not(.is-disabled):hover:last-child,
.set-lang .el-dropdown-menu__item[data-v-2e67d8ac]:focus:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.set-lang .el-dropdown-menu__item[data-v-2e67d8ac] {
  color: #01579b;
}
.set-lang .el-arrow-dl[data-v-2e67d8ac] {
  margin-left: 3px;
  display: inline-block;
}
.set-lang .el-arrow-dl .svg-icon[data-v-2e67d8ac] {
  width: 0.7em;
}
.lang-dropdown[data-v-2e67d8ac] {
  display: block;
  width: 82px !important;
  top: 20px !important;
  left: 0px !important;
  border-radius: 6px;
}