.app-main[data-v-078753dd] {
  /* 50= navbar  50  */
  height: calc(100%);
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fixed-header + .app-main[data-v-078753dd] {
  padding-top: 50px;
}
.hasTagsView .app-main[data-v-078753dd] {
  /* 84 = navbar + tags-view = 50 + 34 */
}
.hasTagsView .fixed-header + .app-main[data-v-078753dd] {
  padding-top: 84px;
}