.help-box ul[data-v-c55e7e98],
.help-box li[data-v-c55e7e98] {
  margin: 0;
  padding: 0;
}
.help-box li[data-v-c55e7e98] {
  list-style: none;
}
.help-box[data-v-c55e7e98]  .el-button--primary {
  background-color: transparent;
  border-color: transparent;
  height: 30px;
  width: 80px;
  padding: unset;
}
.help-box[data-v-c55e7e98]  .el-button--primary:hover,
.help-box[data-v-c55e7e98]  .el-button--primary:focus {
  background: #2e74b9;
  background-color: #2e74b9;
  border-color: #2e74b9;
}
.help-box[data-v-c55e7e98]  .el-dialog:not(.is-fullscreen) {
  margin-top: 70px !important;
  height: 100%;
  max-height: calc(100vh - 90px);
}
.help-box[data-v-c55e7e98]  .el-dialog__wrapper {
  overflow: unset;
  z-index: 100001 !important;
}
.help-box[data-v-c55e7e98]  .el-dialog__title,
.help-box[data-v-c55e7e98]  .el-dialog__body,
.help-box[data-v-c55e7e98]  .el-dialog__footer {
  color: #1f5080;
}
.help-box[data-v-c55e7e98]  .el-dialog__header {
  text-align: left;
  padding: 40px 50px 10px;
}
.help-box[data-v-c55e7e98]  .el-dialog__body {
  padding: 10px 50px 25px;
  height: calc(100vh - 163px);
  overflow-y: auto;
}
.help-box[data-v-c55e7e98]  .el-dialog__body .content,
.help-box[data-v-c55e7e98]  .el-dialog__body .title {
  font-size: 12px;
  margin-bottom: 10px;
}
.help-box[data-v-c55e7e98]  .el-dialog__body .data-brower,
.help-box[data-v-c55e7e98]  .el-dialog__body .fmcc,
.help-box[data-v-c55e7e98]  .el-dialog__body .connect {
  margin-top: 18px;
  word-break: break-word;
}
.help-box[data-v-c55e7e98]  .el-dialog__body .data-brower .title,
.help-box[data-v-c55e7e98]  .el-dialog__body .fmcc .title,
.help-box[data-v-c55e7e98]  .el-dialog__body .connect .title {
  font-weight: 700;
}
.help-box[data-v-c55e7e98]  .el-dialog__body .data-brower img {
  width: 260px;
  margin: 5px 0;
}
.help-box[data-v-c55e7e98]  .el-dialog__body .fmcc .content p {
  line-height: 23px;
}
.help-box[data-v-c55e7e98]  .el-dialog__body .footer {
  float: right;
  font-size: 12px;
  margin-top: 30px;
}
.help-box[data-v-c55e7e98]  .el-dialog__body::-webkit-scrollbar-track {
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) inset;
}