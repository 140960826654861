.key-box[data-v-902692a4] {
  height: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.key-box .app-input[data-v-902692a4]  .el-input__inner {
  background-color: #dfebf9;
}
.key-box .box-card[data-v-902692a4] {
  width: 480px;
  padding: 20px 10px;
}
.key-box[data-v-902692a4]  .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.key-box .btn[data-v-902692a4] {
  margin-left: 10px;
}