.app-footer[data-v-8a272082] {
  overflow: hidden;
  background-color: #f5f5f5;
}
.app-footer .extra[data-v-8a272082] {
  background-color: #01579B;
}
.app-footer .copyright[data-v-8a272082] {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}
.app-footer .copyright p[data-v-8a272082] {
  margin-top: 0;
}